import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { userRequest } from '../utils/requestMethods';
import { formatter, getPriceForTerm } from '../utils/currencyUtils';
import { FiMoreHorizontal } from 'react-icons/fi';
import { Header, AddClassModal, CustomPagination, TableDropdown, SkeletonTable } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import useKYCStatusRedirect from '../hooks/useKYCStatusRedirect';
import * as Icons from '../data/class';

const customStyles = {
  headRow: {
    style: {
      backgroundColor: 'rgb(248, 250, 252)',
      fontWeight: "bold",
    },
  },
};

const Classes = ({ history }) => {
  //useKYCStatusRedirect("/classes");
  const { school } = useStateContext();
  const schoolTerm = school?.settings?.current_term;
  console.log(schoolTerm);
  const [classes, setClasses] = useState();
  const [class_name, setClassname] = useState("");
  const [price1, setPrice1] = useState("");
  const [price2, setPrice2] = useState("");
  const [price3, setPrice3] = useState("");
  const [level_of_education, setLevelOfEducation] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  //const [refresh, setRefresh] = useState(false);
  const schoolID = school?._id;
  const schoolCode = school?.code;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState(null);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  //console.log(accessCode);
  //console.log(schoolCode);

  const fetchClasses = async () => {
    if (schoolCode) {
      try {
        setLoading(true);
        const { data } = await userRequest.get(`classes?code=${schoolCode}`);
        console.log(data.classes);
        setClasses(data.classes);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchClasses();
  }, [schoolCode]);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!class_name || !price1 || !price2 || !price3 || !level_of_education) {
      setError("Please Fill all the Feilds");
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
      return;
    }
    try {
      await userRequest.post("/classes",
        { 
          schoolId:schoolID,
          class_name, 
          class_price: {
            1: price1,
            2: price2,
            3: price3
          },
          level_of_education,
        });
      //console.log(data);
      fetchClasses();
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setClassname("");
        setPrice1("");
        setPrice2("");
        setPrice3("");
        setLevelOfEducation("");
        toggleModal();
      }, 2000);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
    }
    setLoading(false);
  };

  const deleteHandler = async (classId) => {
    //console.log(classId);
    try {
      await userRequest.delete(`classes/${schoolID}/${classId}`);
      //console.log(res.data);
      //setClasses(res.data.classes)
      fetchClasses();
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleDropdownClick = (rowIndex, event) => {
    const rect = event.target.getBoundingClientRect();
    setDropdownIndex(dropdownIndex === rowIndex ? null : rowIndex);
    setDropdownPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
  };

  const handleCloseDropdown = () => setDropdownIndex(null);

  const columns = [
    {
      name: "Class Name",
      selector: row => <span className="font-semibold">{row.class_name}</span>
    },
    {
      name: "Subjects",
      selector: row => <span className="font-semibold">{row.subjects?.length || 0}</span>
    },
    {
      name: "Level of Education",
      selector: row => <span className="font-semibold capitalize">{row.level_of_education}</span>
    },
    {
      name: "Class Fee",
      selector: row => <span className="font-semibold">{formatter.format(getPriceForTerm(row.class_price, schoolTerm))}</span>
    },
    {
      name: "Extra Fee",
      selector: row => <span className="font-semibold">{row.add_on?.length || 0}</span>
    },
    {
      name: "Students",
      selector: row => <span className="font-semibold">{row.studentCount || 0}</span>
    },
    {
      cell: (row, rowIndex) => (
        <div className="relative">
          <button
            className="p-2 text-gray-600 hover:text-gray-900"
            onClick={(e) => handleDropdownClick(rowIndex, e)}
          >
            <FiMoreHorizontal className="text-2xl" />
          </button>
          <TableDropdown
            isVisible={dropdownIndex === rowIndex}
            position={dropdownPosition}
            options={[
              {
                label: "View Students",
                icon: <img src={Icons.checkList} alt="Payout Icon" />,
                onClick: () => history.push(`/students/${row._id}`),
              },
              {
                label: "View Fees",
                icon: <img src={Icons.coupon04} alt="Payout Icon" />,
                onClick: () => history.push(`/addons/${row._id}/${row.class_name}`),
              },
              {
                label: "View Subjects",
                icon: <img src={Icons.notebook} alt="Payout Icon" />,
                onClick: () => history.push(`/subjects/${row._id}`),
              },
              {
                label: "Edit Class Details",
                icon: <img src={Icons.pencilEdit} alt="Payout Icon" />,
                onClick: () => history.push(`/update-class/${row._id}`),
              },
              /*{
                label: "Delete",
                icon: <img src={Icons.delete} alt="Payout Icon" />,
                onClick: () => deleteHandler(row._id),
                danger: true,
              },*/
            ]}
            onClose={handleCloseDropdown}
          />
        </div>
      ),
      width: "120px",
    },
  ];

  // Calculating total subjects and total students
  const totalSubjects = classes?.reduce((acc, currentClass) => acc + currentClass?.subjects?.length, 0);
  const totalStudents = classes?.reduce((acc, currentClass) => acc + currentClass?.studentCount || 0, 0);

  return (
    <>
    <div className="px-10 pt-10">
      <button type="button" onClick={toggleModal} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-[#3F3DA5] hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right">
        Add New Class
      </button>
      <Header category="Set Up" title="Classes" />
    </div>
    
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 mb-2 m-2 md:m-2 mt-5 p-2 md:p-5">
      <div className="bg-white border-2 border-gray-200 dark:text-gray-200 dark:bg-secondary-dark-bg h-34 rounded-3xl p-8 pt-9 m-3">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-gray-400 text-sm">Total Student</p>
            <p className="text-2xl font-semibold">{totalStudents || "0"}</p>
          </div>
          <button type="button" className="bg-[#3371E8] text-white text-2xl opacity-90 text-white hover:drop-shadow-xl rounded-xl py-4 px-4">
            <img src={Icons.userMultiple} alt="Wallet Icon" />
          </button>
        </div>
      </div>

      <div className="bg-white border-2 border-gray-200 dark:text-gray-200 dark:bg-secondary-dark-bg h-34 rounded-3xl p-8 pt-9 m-3">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-gray-400 text-sm">Total Subjects</p>
            <p className="text-2xl font-semibold">{totalSubjects || "0"}</p>
          </div>
          <button type="button" className="bg-[#41D3BD] text-white text-2xl opacity-90 text-white hover:drop-shadow-xl rounded-xl py-4 px-4">
            <img src={Icons.coupon} alt="Payout Icon" />
          </button>
        </div>
      </div>

      <div className="bg-white border-2 border-gray-200 dark:text-gray-200 dark:bg-secondary-dark-bg h-34 rounded-3xl p-8 pt-9 m-3">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-gray-400 text-sm">Total Classes</p>
            <p className="text-2xl font-semibold">{classes?.length || "0"}</p>
          </div>
          <button type="button" className="bg-[#E26E6A] text-white text-2xl opacity-90 text-white hover:drop-shadow-xl rounded-xl py-4 px-4">
            <img src={Icons.taskDaily} alt="Cashflow Icon" />
          </button>
        </div>
      </div>
    </div>

    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl dark:bg-secondary-dark-bg min-h-screen">
      <div className="border rounded-3xl">
        <DataTable
          data={classes}
          columns={columns}
          getRowId={(row) => row._id}
          pagination
          paginationComponent={CustomPagination}
          paginationRowsPerPageOptions={[10, 20, 30, 50]}
          customStyles={customStyles}
          progressPending={loading}
          progressComponent={<SkeletonTable rows={5} columns={6} />}
          highlightOnHover
          striped
        />
      </div>

      {isModalOpen && 
        <AddClassModal 
        closeModal={toggleModal} 
        class_name={class_name}
        setClassname={setClassname}
        //class_price={class_price}
        //setClassPrice={setClassPrice}
        price1={price1} 
        setPrice1={setPrice1} 
        price2={price2} 
        setPrice2={setPrice2} 
        price3={price3} 
        setPrice3={setPrice3}
        level_of_education={level_of_education}
        setLevelOfEducation={setLevelOfEducation}
        error={error}
        success={success}
        loading={loading} 
        submitHandler={submitHandler}
        />
      }
    </div>
    </>
  );
};
export default Classes;
