import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { userRequest } from '../utils/requestMethods';
import { Header, BackButton, SkeletonCards } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import * as Icons from '../data/class';
import { FiDownloadCloud } from 'react-icons/fi';

const LessonNotes = ({ history }) => {
  const location = useLocation();
  const subjectId = location.pathname.split('/')[2];
  const classId = location.pathname.split('/')[3];
  const subject = location.state?.subject;
  const { school } = useStateContext();
  const schoolId = school?._id;
  const schoolTerm = school?.settings?.current_term;
  const schoolSession = school?.settings?.current_session;
  const [subjects, setSubjects] = useState([]);
  const [classInfo, setClassInfo] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchSubjects();
    fetchClass();
  }, [classId, schoolId]);

  const fetchSubjects = async () => {
    try {
      setLoading(true);
      const { data } = await userRequest.get(`teachers/lesson-notes/${schoolId}/${classId}/${subjectId}?term=${schoolTerm}&session=${schoolSession}`);
      //console.log(data.lesson_notes);
      setSubjects(data.lesson_notes);
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchClass = async () => {
    try {
      const { data } = await userRequest.get(`classes/${schoolId}/${classId}`);
      setClassInfo(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownload = (attachmentUrl, title) => {
    const link = document.createElement('a');
    link.href = attachmentUrl;
    link.download = title;
    link.click();
  };

  return (
    <>
      <div className="px-10 pt-6">
        <BackButton history={history} />
      </div>
      <div className="px-10 pt-5">
        <div className="flex flex-wrap justify-between items-center mb-4">
          <Header category="Lesson Notes" title={subject?.subject_name || "Loading..."} />
        </div>
      </div>

      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl dark:bg-secondary-dark-bg">
        <div className="grid grid-cols-4 gap-6">
          {loading ? (
            <SkeletonCards cardCount={4} layout="vertical" width="" />
          ) : subjects.length > 0 ? (
            subjects.map((subject, index) => (
              <div key={index} className='pr-3'>
                <div className='border border-[#C5C5E8] rounded-3xl'>
                  <div className='bg-[#EEEEFC] flex items-center rounded-tr-3xl rounded-tl-3xl  justify-center px-4 py-14'>
                    <img src={Icons.fileType} alt="Nav Icon" />
                  </div>
                  <div className='flex flex-wrap items-center justify-between p-3'>
                    <div>
                      <p className='text-sm font-semibold mt-3'>{subject.title}</p>
                      <p className='text-xs mt-2 mb-3'>Uploaded {(new Date(subject.updated_at)).toLocaleDateString('en-NG')}</p>
                    </div>
                    <div className="flex items-center">
                      <button
                        onClick={() => handleDownload(subject.attachment, subject.title)}
                        className="mt-4 p-2 text-blue-500 rounded-full bg-blue-100 hover:text-blue-700 hover:bg-blue-200"
                      >
                        <FiDownloadCloud className="text-2xl" />
                      </button>
                      <button className="ml-4 mt-4 p-2 text-red-500 rounded-full bg-red-100 hover:text-red-700 hover:bg-red-200">
                        <img src={Icons.delete} alt="Delete Icon" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center w-full text-gray-500">
              <p>No lesson note uploaded for the subject yet.</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LessonNotes;
