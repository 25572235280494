import React, { useState } from "react";
//import AddStudentModal from "./AddStudentModal";
import { FaCloudUploadAlt } from "react-icons/fa";
import { userRequest } from "../../utils/requestMethods";

const AddLessonNoteModal = ({ closeModal, onNoteUploaded, schoolId, classId, teacherId, subjectId }) => {
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const uploadHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!file) {
      setError("Please provide a file!");
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
      return;
    }

    try {
      let studentData = new FormData();
      studentData.append("file", file);
      studentData.append("title", title);
      studentData.append("schoolId", schoolId);
      studentData.append("teacherId", teacherId);
      studentData.append("subjectId", subjectId);
      studentData.append("classId", classId);

      await userRequest.post("/teachers/upload-lesson-note", studentData );
      setSuccess(true);
      onNoteUploaded();
      closeModal();
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex items-center justify-center z-50">
      <div className="bg-white rounded-2xl w-full max-w-md p-6">
        {/* Close Button */}
        <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold">Upload Lesson Note</h2>
            <button type="button" onClick={closeModal} className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span className="sr-only">Close modal</span>
            </button>
        </div>

        <div className="mt-4">
            {error &&
            <div className="p-4 mb-4 text-sm text-white rounded-lg bg-red-500" role="alert">
                <span className="font-medium">Error!</span> {error}
            </div>}
            {success &&
            <div className="p-4 mb-4 text-sm text-white rounded-lg bg-green-500" role="alert">
                <span className="font-medium">Success!</span> {success}
            </div>}
            <form className="space-y-6" onSubmit={uploadHandler}>
              <div>
                <label htmlFor="title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Title</label>
                <input type="text" id="title" onChange={(e) => setTitle(e.target.value)} value={title} placeholder="Title" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
              </div>
              <div className="mt-6">
                <label className="block text-gray-700 text-sm font-medium mt-6 mb-2">
                  ATTACH DOCUMENTS
                </label>
                <label htmlFor="file-upload" className="block border-2 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer">
                  <FaCloudUploadAlt className="text-gray-500 text-4xl mx-auto" />
                  <p className="text-gray-700 mt-4"><span className="text-[#3F3DA5]">Click to upload</span> or drag and drop</p>
                  <p className="text-gray-500">.DOC, .DOCX, .PDF, etc...</p>
                  <input
                    id="file-upload"
                    type="file"
                    onChange={handleFileChange}
                    className="hidden"
                    accept=".pdf"
                  />
                </label>
                {file && (
                <div className="mt-4 text-sm text-gray-700">
                  <p><strong>Selected file:</strong> {file.name}</p>
                  <p><strong>Size:</strong> {(file.size / 1024).toFixed(2)} KB</p>
                </div>
                )}
                <p className="text-gray-500 text-xs mt-2">
                  Accepted File Type: DOC, DOCX, PDF, JPG,JPEG, PNG
                </p>
              </div>
              <div className="flex justify-between items-center">
                <button type="button" onClick={closeModal} className="text-gray-600 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 font-semibold rounded-full text-sm px-5 py-2 mr-3">
                  Close
                </button>
                <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5" disabled={loading}>
                  {loading ? "Uploading..." : "Upload Document"}
                </button>
              </div>
            </form>
        </div>
      </div>
    </div>
  );
};

export default AddLessonNoteModal;
