import React, { useState } from 'react';
import { useFormik } from "formik";
import { acceptInviteSchema } from '../../utils/formSchema';
import { publicRequest } from '../../utils/requestMethods';
import { ButtonLoading, PasswordInput } from '../../components';
import './css/default.css'
import './css/style.css'
import logo from "../../data/logo/logo.png";
import circle from './img/circle.png';
import dot from './img/dot.png';
import signup from './img/sign-up.png';
import zigzag from './img/zigzag.png';
import { useLocation } from 'react-router-dom';

const AcceptInvite = ({ history }) => {
    const location = useLocation();
    const token = location.pathname.split('/')[3];
    console.log(token)
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
    } = useFormik({
        initialValues: {
            firstname: "",
            lastname: "",
            password: "",
        },
        validationSchema: acceptInviteSchema,
        onSubmit: (values) => {
           // Handle form submission here
           acceptHandler(values);
        },
    });
    
    const acceptHandler = async (values) => {
        setLoading(true);
        //console.log(values.code);

        try {
            await publicRequest.post(`/schools/accept-invite`, 
                {
                    token: token,
                    password: values.password,
                    firstname: values.firstname,
                    lastname: values.lastname,
                }
            );
            
            setSuccess("Invite Accepted successfully");
            setTimeout(() => {
                setSuccess("");
                history.push("/signin");
            }, 5000);
        } catch (error) {
            //console.log(error.response.data);
            setError(error.response.data);
            setTimeout(() => {
                setError("");
            }, 5000);
            setLoading(false);
        }
        setLoading(false);
    };

    return (
        <main>
            <section className="signup__area po-rel-z1 p-t-40 pb-100">
                <div className="sign__shape">
                    <img className="circle" src={circle} alt="" />
                    <img className="zigzag" src={zigzag} alt="" />
                    <img className="dot" src={dot} alt="" />
                    <img className="bg" src={signup} alt="" />
                </div>
                <div className="row">
                    <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
                        <div className="section__title-wrapper text-center m-b-40">
                            <img src={logo} alt="logo" className="mx-auto" width="250" />
                        </div>
                    </div>
                </div>

                <div className="max-w-xl mx-auto mt-3 divide-y p-3">
                    {error && (
                        <div className="p-4 my-4 text-sm text-white rounded-lg bg-red-500" role="alert">
                            <span className="font-medium">Error!</span> {error}
                        </div>
                    )}
                    {success && (
                        <div className="p-4 my-4 text-sm text-white rounded-lg bg-green-500" role="alert">
                            <span className="font-medium">Success!</span> {success}
                        </div>
                    )}
                    <div className="sign__wrapper bg-white mx-auto rounded-3xl">
                        <form onSubmit={handleSubmit}>
                            <div className="sign__form px-2">
                                <h3 className="text-2xl font-bold text-center">Accept Invite</h3>
                                <p className="mb-25 text-center">Provide the needed information to accept the invitation.</p>
                                <div className="sign__input-wrapper mb-25">
                                    <h5>Full Name</h5>
                                    <div className="lg:grid lg:grid-cols-2 lg:gap-x-2">
                                        <div className="sign__input mb-2">
                                            <input type="text" id="firstname" placeholder="First name" value={values.firstname} onBlur={handleBlur} onChange={handleChange} required/>
                                            <i className="fal fa-user"></i>
                                            {errors.firstname && touched.firstname &&
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500"><span className="font-medium">Oops!</span> {errors.firstname}</p>}
                                        </div>
                                        <div className="sign__input">
                                            <input type="text" id="lastname" placeholder="Last name" value={values.lastname} onBlur={handleBlur} onChange={handleChange} required />
                                            <i className="fal fa-user"></i>
                                            {errors.lastname && touched.lastname &&
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500"><span className="font-medium">Oops!</span> {errors.lastname}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="sign__input-wrapper mb-6">
                                    <div className="sign__input">
                                        <PasswordInput
                                            id="password"
                                            label="Create Password"
                                            placeholder="Password"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errorMessage={errors.password && touched.password ? errors.password : ""}
                                        />
                                    </div>
                                </div>
                                {loading ?
                                    (
                                        <ButtonLoading styles="e-btn w-100 auth-btn" />
                                    ) : (
                                        <button type="submit" className="e-btn w-100 auth-btn">Accept</button>
                                    )
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </main>
    );
};
export default AcceptInvite;