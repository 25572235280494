import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import customPagination from '../../components/CustomPagination/CustomPagination';
import { userRequest } from '../../utils/requestMethods';
import { useStateContext } from '../../contexts/ContextProvider';
import { MdSearch } from 'react-icons/md';
import { Link } from 'react-router-dom';
import * as Icons from '../../data/class';
import { NoDataComponent, SkeletonTable } from '../../components';

const customStyles = {
    headRow: {
        style: {
            backgroundColor: 'rgb(248, 250, 252)',
            fontWeight: "bold",
        },
    },
};

export default function AttendanceManagement({ history }) {
    const { user, school } = useStateContext();
    console.log(school);
    const schoolTerm = school?.settings?.current_term;
    const schoolSession = school?.settings?.current_session;
    const currentTermData = school?.settings?.terms.find(term => term.term === schoolTerm);
    const startDate = currentTermData?.start_date;
    const endDate = currentTermData?.end_date;
    const [filterText, setFilterText] = useState('');
    const [studentAttendance, setStudentAttendance] = useState([]);
    const [totals, setTotals] = useState({ totalStudents: 0, totalAttendanceRate: 0, totalDays: 0 });
    const [loading, setLoading] = useState(false);
    const [classes, setClasses] = useState([]);
    const [dropdown, setDropdown] = useState(false);
    const [selectedClass, setSelectedClass] = useState(() => {
        const savedClass = JSON.parse(localStorage.getItem('selectedClass'));
        return savedClass || { id: null, name: 'All classes' };
    });
    const handleDropdown = () => setDropdown(!dropdown);

    const calculateTotals = (processedData) => {
        const totalStudents = processedData.length;
        const totalAttendanceRate = totalStudents > 0
            ? (processedData.reduce((sum, student) => sum + parseFloat(student.AttendanceRate), 0) / totalStudents).toFixed(2)
            : 0;
        const totalDays = processedData.reduce((sum, student) => sum + student.DaysAttended + student.DaysAbsent, 0);
    
        return {
            totalStudents,
            totalAttendanceRate,
            totalDays,
        };
    };    

    // Utility function to calculate attendance metrics
    const processAttendanceData = (students) => {
        return students.map(student => {
            const totalDays = student.attendance.reduce((sum, record) => sum + record.records.length, 0);
            const daysAttended = student.attendance.reduce((sum, record) => (
                sum + record.records.filter(r => r.status === 1).length
            ), 0);
            const daysAbsent = totalDays - daysAttended;
            const attendanceRate = totalDays > 0 ? ((daysAttended / totalDays) * 100).toFixed(2) : 0;

            return {
                key: `${student.student.id}`,
                id: `${student.student.id}`,
                name: `${student.student.firstname} ${student.student.lastname}` || "Unknown",
                student: `${student.student}`,
                class: student.class || "N/A",
                AttendanceRate: `${attendanceRate}%`,
                DaysAttended: daysAttended,
                DaysAbsent: daysAbsent,
            };
        });
    };

    useEffect(() => {
        const fetchClasses = async () => {
            if (school) {
                try {
                    setLoading(true);
                    const { data } = await userRequest.get(`classes?code=${school?.code}`);
                    setClasses(data.classes || []);
                } catch (error) {
                    console.error("Error fetching classes:", error);
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchClasses();
    }, [school]);

    const fetchAttendance = async () => {
        setLoading(true);
        try {
            const { data } = await userRequest.get(`/attendance/class/${selectedClass.id || ''}?school_id=${school?._id}&term=${schoolTerm}&session=${schoolSession}&from=${startDate}&to=${endDate}&limit=50`);
            console.log(data?.students);
            const processedData = processAttendanceData(data?.students || []);
            setStudentAttendance(processedData);
            
            // Calculate totals and update state
            const totals = calculateTotals(processedData);
            setTotals(totals);
        } catch (error) {
            console.error("Error fetching attendance:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedClass.id) fetchAttendance();
    }, [school?._id, selectedClass.id]);

    const handleClassSelection = (id, name) => {
        const newSelection = { id, name };
        setSelectedClass(newSelection);
        localStorage.setItem('selectedClass', JSON.stringify(newSelection));
        setDropdown(false);
    };

    const columns = [
        { name: "Name", selector: row => row.name },
        { name: "Class", selector: row => selectedClass.name },
        { name: "Attendance Rate (%)", selector: row => row.AttendanceRate },
        { name: "Days Attended", selector: row => row.DaysAttended },
        { name: "Days Absent", cell: row => <p className="text-[#D05151]">{row.DaysAbsent}</p> },
        {
            name: "Action",
            cell: row => (
                <Link to={{ pathname: `/students/view/${row.id}/${selectedClass.id}`, state: { student: row.name } }}>
                    <button className="text-[#3F3DA5] hover:font-semibold">View Attendance</button>
                </Link>
            ),
        },
    ];

    const filteredData = studentAttendance?.filter((item) =>
        (item.name?.toLowerCase().includes(filterText.toLowerCase()) || '') 
    );

    return (
        <div className="w-full flex items-center justify-center py-14 md:py-9 relative">
            <div className="bg-transparent pt-4 md:pt-0 w-full pb-16 px-9">
                <h2 className="text-2xl font-bold mb-3">Attendance</h2>
                <div className='flex flex-wrap items-center justify-start'>
                    <div className='w-full sm:w-1/2 lg:w-1/3 pr-0 sm:pr-3'>
                        <div className='bg-white w-full px-3 py-5 flex items-center justify-between rounded-2xl border border-[#E7E7E7]'>
                            <div className='pr-2'>
                                <p className='text-sm text-[#656565]'>Total Student</p> 
                                <p className='text-2xl font-semibold mt-1'>{totals.totalStudents || 0}</p>
                            </div>
                            <button type="button" className="bg-[#3371E8] text-white text-2xl opacity-90 text-white hover:drop-shadow-xl rounded-xl py-4 px-4">
                                <img src={Icons.userMultiple} alt="Wallet Icon" />
                            </button>
                        </div>
                    </div>

                    <div className='w-full mt-4 sm:mt-0 sm:w-1/2 lg:w-1/3 px-0 sm:px-3'>
                        <div className='bg-white w-full px-3 py-5 flex items-center justify-between rounded-2xl border border-[#E7E7E7]'>
                            <div className='pr-2'>
                                <p className='text-sm text-[#656565]'>Total Attendance Rate</p> 
                                <p className='text-2xl font-semibold mt-1'>{totals.totalAttendanceRate || 0}%</p>
                            </div>
                            <button type="button" className="bg-[#41D3BD] text-white text-2xl opacity-90 text-white hover:drop-shadow-xl rounded-xl py-4 px-4">
                                <img src={Icons.coupon} alt="Payout Icon" />
                            </button>
                        </div>
                    </div>

                    <div className='w-full sm:w-1/2 mt-4 sm:mt-6 lg:mt-0 lg:w-1/3 pl-0 lg:pl-3 pr-0 sm:pr-3 lg:pr-0'>
                        <div className='bg-white w-full px-3 py-5 flex items-center justify-between rounded-2xl border border-[#E7E7E7]'>
                            <div className='pr-2'>
                                <p className='text-sm text-[#656565]'>Total Days</p> 
                                <p className='text-2xl font-semibold mt-1'>{totals.totalDays || 0}</p>
                            </div>
                            <button type="button" className="bg-[#E26E6A] text-white text-2xl opacity-90 text-white hover:drop-shadow-xl rounded-xl py-4 px-4">
                                <img src={Icons.taskDaily} alt="Cashflow Icon" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="bg-white rounded-2xl mt-6 px-6 py-7">
                    <div className='w-full'>
                        <div className='flex flex-wrap items-center justify-between mb-6'>
                            <div className='relative w-max text-[#4543B5] text-xs'>
                                <button type='button' onClick={handleDropdown} className='w-full text-sm font-bold flex items-center justify-between bg-transparent hover:bg-blue-50 border-1 border-[#C5C5E8] py-2 px-3 rounded-full'>
                                    <p className='mr-2'>Filter: {selectedClass.name}</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="inline ml-2 icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
                                </button>
                                {dropdown && (
                                    <div className="w-full absolute bg-white border rounded shadow-md mt-2 z-10">
                                        {classes.map(classe => (
                                            <div
                                                key={classe._id}
                                                onClick={() => handleClassSelection(classe._id, classe.class_name)}
                                                className="cursor-pointer text-md px-4 py-2 hover:bg-gray-100"
                                            >
                                                {classe.class_name}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className='flex flex-wrap mt-4 md:mt-0 w-full md:w-max items-center justify-between md:justify-end'>
                                <form className="max-w-lg">
                                    <div className="relative">
                                        <div className="absolute inset-y-0 start-0 flex items-center pl-4 pointer-events-none">
                                            <MdSearch />
                                        </div>
                                        <input
                                            type="text"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full pl-8 p-2.5"
                                            placeholder="Search"
                                            value={filterText}
                                            onChange={(e) => setFilterText(e.target.value)}
                                        />
                                    </div>
                                </form>
                                <button className='hover:bg-indigo-900 rounded-full text-white mx-2 py-3 px-5 bg-[#3F3DA5]'>Export</button>
                            </div>
                        </div>
                    </div>
                    <p className="text-2xl font-semibold mb-3">Attendance Register</p>
                    <div className="border rounded-3xl">
                        <DataTable
                            columns={columns}
                            data={filteredData}
                            noDataComponent = {<NoDataComponent/>}
                            pagination
                            paginationComponent={customPagination}
                            paginationRowsPerPageOptions={[10, 20, 30, 50]}
                            customStyles={customStyles}
                            progressPending={loading}
                            progressComponent={<SkeletonTable rows={5} columns={6} />}
                            highlightOnHover
                            striped
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
