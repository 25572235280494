import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { userRequest } from '../../../utils/requestMethods';
import { BackButton, CustomPagination, SkeletonTable } from '../../../components';
import { useStateContext } from '../../../contexts/ContextProvider';
import ResultDetailsModal from "../../../components/teacherModule/results/ResultDetailsModal";
import AddCommentModal from "../../../components/teacherModule/results/AddCommentModal";

const customStyles = {
    headRow: {
        style: {
            backgroundColor: 'rgb(248, 250, 252)',
            fontWeight: "bold",
        },
    },
};

const UploadStudentResult = ({ history }) => {
    const location = useLocation();
    const studentId = location.pathname.split('/')[4];
    const student = location.state?.student;
    const classId = student.class_info._id;
    const { user, school } = useStateContext();

    const [subjects, setSubjects] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);

    const toggleResultModal = (subject = null) => {
        setSelectedSubject(subject);
        setIsModalOpen(!isModalOpen);
    };

    const toggleCommentModal = () => {
        setIsCommentModalOpen(!isCommentModalOpen);
    };

    const fetchSubjects = async () => {
        setLoading(true);
        try {
            const { data } = await userRequest.get(`/subjects/${school?._id}/${classId}?limit=100`);
            setSubjects(data.subjects || []);
        } catch (error) {
            console.error("Error fetching subjects:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSubjects();
    }, [school?._id, user?._id]);

    const columns = [
        {
            name: "Subject Name",
            selector: row => row.subject_name,
        },
        {
            name: "Description",
            selector: row => row.subject_description,
        },
        {
            name: "Subject Code",
            selector: row => row.subject_code,
        },
        {
            name: "Class",
            selector: row => row.class.class_name,
        },
        {
            name: 'Action',
            cell: (row) => (
                <button onClick={() => toggleResultModal(row)} className="font-medium text-[#00509D] hover:underline">
                    Upload
                </button>
            ),
            ignoreRowClick: true,
            button: true,
            width: "200px",
        },
    ];

    return (
        <div className="w-full flex items-center justify-center py-14 md:py-8 relative">
            <div className="bg-transparent w-full pb-16 px-3 md:px-9 py-8 sm:py-0">
                <div className="bg-white rounded-2xl p-6">
                    <BackButton history={history} />
                    <div className="w-14 h-14 rounded-full bg-blue-500 mb-4 mt-4 border-[3px] border-[#D8846E]"></div>
                    <div className="flex flex-wrap items-center justify-between">
                        <div className="w-full sm:w-max mb-4 sm:mb-0">
                            <p className="text-2xl font-semibold mb-2">{student?.firstname} {student?.lastname}</p>
                            <p className="text-sm text-[#606060] font-medium">
                                {school?.settings.current_term} Term - {school?.settings.current_session}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="mt-10 p-5 md:p-10 bg-white rounded-3xl dark:bg-secondary-dark-bg">
                    <div className="flex flex-wrap justify-between items-center mb-4">
                        <h1 className="text-lg font-semibold w-full md:w-auto mb-2 md:mb-0" style={{ maxWidth: "20em" }}>
                            Upload Student Result
                        </h1>
                        
                    </div>

                    <div className="border rounded-3xl">
                        <DataTable
                            data={subjects}
                            columns={columns}
                            getRowId={(row) => row._id}
                            pagination
                            paginationComponentOptions={{
                                rowsPerPageText: 'Rows per page:',
                                rangeSeparatorText: 'of',
                                selectAllRowsItem: true,
                                selectAllRowsItemText: 'All',
                            }}
                            paginationComponent={CustomPagination}
                            paginationRowsPerPageOptions={[10, 20, 30, 50]}
                            customStyles={customStyles}
                            progressPending={loading}
                            progressComponent={<SkeletonTable rows={5} columns={6} />}
                            highlightOnHover
                            striped
                        />
                    </div>
                </div>
            </div>
            {isModalOpen && 
                <ResultDetailsModal
                    closeModal={toggleResultModal} 
                    onResultUploaded={fetchSubjects}
                    childId={student._id}
                    teacherId={user._id}
                    school={school}
                    classId={classId}
                    subject={selectedSubject}
                />
            }
            {isCommentModalOpen && 
                <AddCommentModal
                    closeModal={toggleCommentModal} 
                    onCommentAdded={fetchSubjects}
                    childId={student._id}
                    teacherId={user._id}
                    school={school}
                    classId={classId}
                />
            }
        </div>
    );
};

export default UploadStudentResult;
