import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import * as Icons from '../../data/teacher';
import { userRequest } from '../../utils/requestMethods';
import SkeletonCards from '../common/SkeletonCards';

const ClassesSection = ({ teacher, school }) => {
    const [classes, setClasses] = useState([]);
    const [loadingClasses, setLoadingClasses] = useState(false);

    const fetchSubjects = async () => {
        setLoadingClasses(true);
        try {
            const { data } = await userRequest.get(`/teachers/assigned-classes/${school?._id}/${teacher._id}?limit=100`);
            console.log(data);
            setClasses(data.classes || []);
        } catch (error) {
            console.error("Error fetching subjects:", error);
        } finally {
            setLoadingClasses(false);
        }
    };
    
    useEffect(() => {
        fetchSubjects();
    }, [school?._id, teacher._id]);

  return (
    <div className='flex flex-wrap items-center justify-between mt-6 mb-6'>
        {loadingClasses ? (
            <SkeletonCards cardCount={3} layout="vertical" width="md:w-1/3 w-1/2" />
        ) : classes.length > 0 ? (
            classes.map((classe, index) => (
            <div key={classe._id || index} className='pr-3 md:w-1/3 w-1/2 mb-3'>
                <div className='border border-[#C5C5E8] p-3 rounded-2xl'>
                    <div className='bg-[#EEEEFC] flex items-center justify-center rounded-2xl px-4 py-14'>
                        <img src={Icons.book} alt="Nav Icon" />
                    </div>
                    <div>
                        <p className='text-sm font-semibold mt-3'>{classe.class_name || 'Class Name'}</p>
                        <p className='text-xs mt-2 mb-3'>{classe.total_students || 0} Students</p>
                    </div>
                    <div className='flex flex-wrap items-center justify-between'>
                        <button className='sm:w-1/2 w-full bg-[#E26E6A1A] text-[#E26E6A] hover:bg-red-100 font-semibold text-xs sm:px-10 px-2 py-2 rounded-full flex items-center justify-center'>
                            Unassign</button>
                        <div className='sm:pr-3 pr-0 sm:w-1/2 w-full sm:mt-0 mt-2 sm:pl-2 pl-0 flex items-center justify-end'>
                            <Link to={`/students/${classe._id}`} className='w-full'>
                                <button className='w-full bg-[#4543B5] hover:bg-indigo-900 text-white font-semibold text-xs sm:px-10 px-2 py-2 rounded-full flex items-center justify-center'>
                                    View
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            ))
        ) : (
        <div className="text-center w-full text-gray-500">
            Teacher is not assigned to any class as a class teacher.
        </div>
        )}
    </div>
  )
}

export default ClassesSection