import React, { useEffect, useState } from 'react'
import * as Icons from '../../data/teacher';
import { userRequest } from '../../utils/requestMethods';
import SkeletonCards from '../common/SkeletonCards';
import { Link } from 'react-router-dom';

const AssignedSubjectsSection = ({ teacher, school }) => {
  const [subjects, setSubjects] = useState([]);
  const [loadingClasses, setLoadingClasses] = useState(false);

  const fetchSubjects = async () => {
    setLoadingClasses(true);
    try {
      const { data } = await userRequest.get(`/subjects/assigned/${school?._id}/${teacher._id}?limit=100`);
      console.log(data);
      setSubjects(data.subjects || []);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    } finally {
      setLoadingClasses(false);
    }
  };
  
  useEffect(() => {
      fetchSubjects();
  }, [school?._id, teacher._id]);

  return (
    <div className='flex flex-wrap items-center mt-6 mb-6'>
      {loadingClasses ? (
        <SkeletonCards cardCount={3} layout="vertical" width="md:w-1/3 w-full mb-2"/>
      ) : subjects.length > 0 ? (
        subjects.map((subject, index) => (
        <div key={subject._id || index} className='pr-3 md:w-1/3 w-full mb-2'>
            <div className='border border-[#C5C5E8] p-3 rounded-2xl'>
                <div className='bg-[#EEEEFC] flex items-center justify-center rounded-2xl px-4 py-14'>
                    <img src={Icons.book} alt="Nav Icon" />
                </div>
                <div>
                    <p className='text-sm font-semibold mt-3'>{subject.subject_name || 'Subject Name'}</p>
                    <p className='text-xs mt-2 mb-3'>{subject.class.class_name || 'Primary'}</p>
                    <p className='text-xs mt-2 mb-3'>{subject.lesson_note.length || 0} Lesson Notes</p>
                </div>
                <Link to={{ pathname: `/t/subjects/notes/${subject._id}/${subject.class._id}`, state: { subject: subject } }} className='w-full mt-2 sm:pl-2 pl-0 flex items-center justify-end'>
                  <button className='w-full bg-[#4543B5] hover:bg-indigo-900 text-white font-semibold text-xs sm:px-10 px-2 py-2 rounded-full flex items-center justify-center'>
                    View Lesson Notes
                  </button>
                </Link>
            </div>
        </div>
        ))
      ) : (
        <div className="text-center w-full text-gray-500">
          No subjects assigned to this teacher yet.
        </div>
      )}
    </div>
  )
}

export default AssignedSubjectsSection