import { Button } from "../../components/adminDashboard/ResultsComponents";
import React, {useState} from "react"
import { MdClose } from "react-icons/md"
import { MdArrowBack, MdArrowForward } from "react-icons/md"
import { useHistory } from "react-router-dom";
import ContinousAssessment from "../../components/adminDashboard/ContinousAssessment";
import RemarksAndMore from "../../components/adminDashboard/RemarksAndMore";
import GeneralCustomization from "../../components/adminDashboard/GeneralCustomization";

const ranges = Array.from({ length: 101 },(_, i) => i);
const grades = Array.from({ length: 6 },(_, i) => i)
const sections = ["Continous Assessment", "Grading System", "Remarks, abilities, & trait reporting", "General Customization"]
const Setup = ({onClose}) => {
  const history = useHistory()
  const [page, setPage] = useState(0)
  const [error, setError] = useState(false)
  const [ca, setCa] = useState([
    {name: 0, value: 0}, 
    {name: 1, value: 0}, 
    {name: 2, value: 0}
  ])
  const [range, setRanges] = useState([
    {name: "A", value: [70, 100]},
    {name: "B", value: [60, 69]},
    {name: "C", value: [50, 59]},
    {name: "D", value: [45, 49]},
    {name: "E", value: [40, 44]},
    {name: "F", value: [0, 39]}
  ])
  const [preview, setPreview] = useState(true)
  const [review, setReview] = useState(true)
  const [customize, setCustomize] = useState(true)
  const [remarksAndMore, setRemarksAndMore]= useState()
  const pages = [0, 1, 2, 3]
  const handleProceed = () => {
    let allInfo = {}
    allInfo.assessment = ca
    allInfo.grading = range
    allInfo.remarksAndMore = remarksAndMore? remarksAndMore : {}
    allInfo.customization = {
      preview,
      review,
      customize
    }
    localStorage.setItem("summary", JSON.stringify(allInfo))
    history.push("/result-management/summary")
  }
  return(
    <div className="fixed top-0 left-0 p-3 flex items-center justify-center min-h-screen h-full w-full bg-[#34405480] backdrop-blur-sm">
      <div className="w-full max-w-3xl max-h-[85%] sm:max-h-[90%] bg-white mt-12 md:mt-0 px-5 py-6 rounded-3xl overflow-y-scroll">
        <div className="flex justify-between items-center gap-2">
          <p className="text-lg font-extrabold tracking-tight text-slate-900 dark:text-gray-200">
            Setup Result Metrics
          </p>

          <button onClick={onClose}><MdClose size={24}/></button>
        </div>

        <div className="my-6">
          <p className="text-sm text-gray-400">{sections[page]}</p>
          <div className="flex gap-1">
            {pages.map(currentPage => <div className={`min-w-[50px] ${currentPage === page ? "w-44 bg-blue-800" : "bg-gray-400"} h-[2px] rounded`}></div>)}
          </div>
        </div>

        {
          page === 0 && 
          <ContinousAssessment ca={ca} setCa={setCa} error={error} setError={setError}/>
        }

        {
          page === 1 &&
          <div className="grid sm:grid-cols-2 gap-2 md:gap-3">
            {grades.map(grade => 
            <GradeMetrics 
            key={range[grade].name} 
            grade={grade} 
            state={range} 
            setState={setRanges}/>)}
          </div>
        }

        {
          page === 2 &&
          <RemarksAndMore setFinalState={setRemarksAndMore}/>
        }

        {
          page === 3 &&
          <GeneralCustomization 
          review={review}
          preview={preview}
          customize={customize}
          setReview={setReview}
          setPreview={setPreview}
          setCustomize={setCustomize}/>
        }

        <div className="flex mt-3 justify-between gap-2">
          <Button 
          onClick={() => setPage(page-1)}
          disabled={page === pages[0]} variant={2}
          >
            <MdArrowBack/> Back
          </Button>
          
          {
            page === 3 ? 
            <Button onClick={handleProceed}>
              Proceed to Summary
            </Button> : 
            <Button 
              onClick={() => setPage(page+1)}
              disabled={page === pages[pages.length - 1] || error}
            >
              Proceed <MdArrowForward/>
            </Button>
          }
        </div>
      </div>
    </div>
  )
}

const GradeMetrics = ({grade, state, setState}) => {
  const updateStartValue = (e) => {
    const score = parseInt(e.target.value ?? "0")
    const value = [score, state[grade].value[1]]
    setState(state.map(item => item.name === state[grade].name ? 
      {...item, value} : item))
  }
  const updateEndValue = (e) => {
    const score = parseInt(e.target.value ?? "0")
    const value = [state[grade].value[0], score]
    setState(state.map(item => item.name === state[grade].name ? 
      {...item, value} : item))
  }
  return(
    <div className="flex flex-wrap gap-3 items-start justify-between mx-auto max-w-sm w-full p-4 border rounded-3xl">
      <p className="text-base font-semibold">{state[grade].name}</p>
      <div>
        <p>Weight</p>
        <div className="flex justify-between gap-2 items-center">
          <div className="rounded-sm overflow-hidden border">
            <select value={state[grade].value[0]} onChange={updateStartValue} className="block border-none">
              <optgroup className="max-h-[60px]">
                {ranges.map(num => <option>{num}</option>)}
              </optgroup>
            </select>
          </div>

          <p>-</p>

          <div className="rounded-sm overflow-hidden border">
            <select onChange={updateEndValue} value={state[grade].value[1]} className="border-none">
              <optgroup className="max-h-[60px]">
                {ranges.map(num => <option>{num}</option>)}
              </optgroup>
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Setup}