import React, { useState, useEffect } from 'react';
import { userRequest } from '../../utils/requestMethods';
import { AssignedSubjectsSection, AssignedClassesSection } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import * as Icons from '../../data/class';
import { displaySchoolTerm } from '../../utils/schoolYearUtils';

const Classes = ({ history }) => {
  const [board, setBoard] = useState('classes')
  const { user, school } = useStateContext();
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log(school);
  //console.log(user);

  const fetchClasses = async () => {
    setLoading(true);
    try {
      const { data } = await userRequest.get(`/teachers/assigned-classes/${school?._id}/${user?._id}?limit=100`);
      console.log(data);
      setClasses(data.classes || []);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSubjects = async () => {
    setLoading(true);
    try {
      const { data } = await userRequest.get(`/subjects/assigned/${school?._id}/${user?._id}?limit=100`);
      console.log(data);
      setSubjects(data.subjects || []);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClasses();
    fetchSubjects();
  }, [school?._id, user?._id]);

  return (
    <>
    <div className="mt-10">
      <div className="flex flex-col md:flex-row md:justify-between md:items-center relative px-4 md:px-10 pt-8 space-y-4 md:space-y-0">
        <div>
          <h1 className="text-lg md:text-2xl font-extrabold mb-2">Welcome Back, {user?.firstname}</h1>
        </div>
        <p className="text-xs md:text-sm font-semibold md:text-left">
          {displaySchoolTerm(school?.term)} - {school?.session}
        </p>
      </div>
    </div>
    
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 mb-2 m-2 md:m-2 mt-5 p-2 md:p-5">
      <div className="bg-white border-2 border-gray-200 dark:text-gray-200 dark:bg-secondary-dark-bg h-34 rounded-3xl p-8 pt-9 m-1 md:m-3">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-gray-400 text-sm">Student</p>
            <p className="text-2xl font-semibold">
              {classes?.reduce((total, currentClass) => total + (currentClass.total_students || 0), 0) || "0"}
            </p>
          </div>
          <button type="button" className="bg-[#3371E8] text-white text-2xl opacity-90 text-white hover:drop-shadow-xl rounded-xl py-4 px-4">
            <img src={Icons.userMultiple} alt="Wallet Icon" />
          </button>
        </div>
      </div>

      <div className="bg-white border-2 border-gray-200 dark:text-gray-200 dark:bg-secondary-dark-bg h-34 rounded-3xl p-8 pt-9 m-1 md:m-3">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-gray-400 text-sm">Classes</p>
            <p className="text-2xl font-semibold">{classes?.length || "0"}</p>
          </div>
          <button type="button" className="bg-[#41D3BD] text-white text-2xl opacity-90 text-white hover:drop-shadow-xl rounded-xl py-4 px-4">
            <img src={Icons.coupon} alt="Payout Icon" />
          </button>
        </div>
      </div>

      <div className="bg-white border-2 border-gray-200 dark:text-gray-200 dark:bg-secondary-dark-bg h-34 rounded-3xl p-8 pt-9 m-1 md:m-3">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-gray-400 text-sm">Subjects</p>
            <p className="text-2xl font-semibold">{subjects?.length || "0"}</p>
          </div>
          <button type="button" className="bg-[#E26E6A] text-white text-2xl opacity-90 text-white hover:drop-shadow-xl rounded-xl py-4 px-4">
            <img src={Icons.taskDaily} alt="Cashflow Icon" />
          </button>
        </div>
      </div>
    </div>

    <div className='m-2 md:m-10 mt-15 p-4 md:p-10 bg-white rounded-3xl dark:bg-secondary-dark-bg min-h-screen'>
      <div className='flex flex-wrap items-center'>
        <button onClick={() => { setBoard('subjects') }} className={board === 'classes' ? 'sm:w-max w-full bg-white hover:bg-indigo-300 border border-[#C5C5E8] text-[#3F3DA5] mr-4 font-semibold text-sm px-4 py-2 rounded-full items-center justify-between'
          : 'sm:w-max w-full bg-[#C5C5E8] hover:bg-indigo-300 border border-[#C5C5E8] text-[#3F3DA5] mr-4 font-semibold text-sm px-4 py-2 rounded-full items-center justify-between'
        }>
          Assigned Subjects
        </button>

        <button onClick={() => { setBoard('classes') }} className={board === 'subjects' ? 'sm:w-max w-full mt-3 sm:mt-0 bg-white hover:bg-indigo-300 border border-[#C5C5E8] text-[#3F3DA5] mr-4 font-semibold text-sm px-4 py-2 rounded-full items-center justify-between'
          : 'sm:w-max w-full mt-3 sm:mt-0 bg-[#C5C5E8] hover:bg-indigo-300 border border-[#C5C5E8] text-[#3F3DA5] mr-4 font-semibold text-sm px-4 py-2 rounded-full items-center justify-between'
        }>
          Assigned Classes
        </button>
      </div>
      { board === 'classes' ?
        <AssignedClassesSection teacher={user} school={school} />
        :
        <AssignedSubjectsSection teacher={user} school={school} />
      }
    </div>
    </>
  );
};
export default Classes;
