import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';

const useKYCStatusRedirect = (selectedPath) => {
  const { user, school } = useStateContext();
  const history = useHistory();

  useEffect(() => {
    //console.log(user);
    if (!school) return; // If school data is not available, do nothing

    const redirectBasedOnKYCStatus = () => {
      if (school.kyc_progress >= 8) {
        // Redirect to the selected path if kyc_progress is above 8
        history.push(selectedPath);
      } else {
        switch (school.kyc_progress) {
          case 1:
          case 2:
          case 3:
          case 4:
            // Redirect to onboarding status page
            history.push('/onboarding/status');
            break;
          case 0:
            // Redirect to KYC page
            history.push('/onboarding/kyc');
            break;
          case 5:
            // Redirect to rejected page
            history.push('/onboarding/get-started');
            break;
          default:
            // Redirect to home page
            history.push('/onboarding/get-started');
            break;
        }
      }
    };
    if (user.is_school) {
      redirectBasedOnKYCStatus();
    } else {
      history.push('/teacher');
    }
  }, [school, history, selectedPath]);

  return null; // This hook doesn't return any JSX
};

export default useKYCStatusRedirect;
