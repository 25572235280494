import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { userRequest } from "../../utils/requestMethods";

const AssignClassModal = ({ onClose, teacher, school }) => {
  const [classId, setClassId] = useState("");
  const [classes, setClasses] = useState([]);
  const [loadingClasses, setLoadingClasses] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    let isMounted = true;

    const fetchClasses = async () => {
      if (!school?.code) return;

      try {
        setLoadingClasses(true);
        const { data } = await userRequest.get(`classes?code=${school?.code}`);
        if (isMounted) setClasses(data.classes || []);
      } catch (error) {
        if (isMounted) console.error("Error fetching classes:", error);
      } finally {
        if (isMounted) setLoadingClasses(false);
      }
    };

    fetchClasses();

    return () => {
      isMounted = false;
    };
  }, [school?.code]);

    const submitHandler = async (e) => {
        e.preventDefault();
        setError("");
        setSuccess("");

        if (!classId) {
            setError("Please select a class.");
            return;
        }

        try {
            setLoading(true);
            await userRequest.post(`/teachers/assign-class`, {
                schoolId: school._id,
                teacherId: teacher._id,
                classId,
            });
            setSuccess("Class assigned successfully!");
            onClose(); // Close the modal after a successful submission
        } catch (error) {
            console.error("Error assigning class:", error);
            setError(error.response?.data || "An unexpected error occurred.");
        } finally {
            setLoading(false);
        }
    };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
      <div className="bg-white rounded-2xl w-full md:w-1/3 p-6 shadow-lg">
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Assign as Class Teacher</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <FaTimes />
          </button>
        </div>

        {/* Error or Success Message */}
        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
        {success && <p className="text-green-500 text-sm mb-4">{success}</p>}

        {/* Dropdown */}
        <div className="mb-4">
          <label className="block text-sm text-gray-600 mb-2">Select Class</label>
          <select
            onChange={(e) => setClassId(e.target.value)}
            value={classId}
            className="w-full border border-gray-300 rounded-lg p-2 text-sm focus:ring-2 focus:ring-indigo-500 focus:outline-none"
            disabled={loading}
          >
            <option value="" disabled>
              {loadingClasses ? "Loading classes..." : "Select a Class"}
            </option>
            {classes.length > 0 ? (
              classes.map((classe) => (
                <option key={classe._id} value={classe._id}>
                  {classe.class_name}
                </option>
              ))
            ) : (
              <option disabled>No classes available</option>
            )}
          </select>
        </div>

        {/* Submit Button */}
        <div className="text-right">
            <button onClick={submitHandler} className="px-5 py-3 bg-[#3F3DA5] text-white text-sm font-semibold rounded-full hover:bg-indigo-800 transition"disabled={loading}>
                {loading ? "Assigning..." : "Assign Now"}
            </button>
        </div>
      </div>
    </div>
  );
};

export default AssignClassModal;
