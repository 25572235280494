import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AssignClassModal, AssignSubjectsModal, BackButton, ClassesSection, SubjectsSection } from '../../components';
import { FiChevronDown, FiX } from "react-icons/fi";
import { useLocation } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';



export default function ViewTeacher() {
    const location = useLocation();
    const teacher = location.state?.teacher;
    const { school } = useStateContext();

    console.log(teacher);

    const [board, setBoard] = useState('classes')
    const [assignClassModal, setAssignClassModal] = useState(false)
    const [unassignClassModal, setUnassignClassModal] = useState(false)
    const [unassignSubjectModal, setUnassignSubjectModal] = useState(false)
    const [assignSubjectModal, setAssignSubjectModal] = useState(false)
    const [dropdown, setDropdown] = useState(false)

    const theBody = document.body
    const showAssignClassModal = 'flex z-50 fixed h-screen top-0 right-0 left-0 items-center justify-center'
    const hideAssignClassModal = 'hidden'
    const hideBackg = 'hidden'
    const showBackg = 'flex z-40 fixed h-screen top-0 right-0 left-0 items-center justify-center bg-[#34405480] backdrop-blur-sm'

    const handleShowAssignClassModal = () => {
        console.log("Screen width:", window.innerWidth);
        setAssignClassModal(true)
        theBody.style.overflow = 'hidden'
    }
    const handleHideAssignClassModal = () => {
        setAssignClassModal(false)
        theBody.style.overflow = 'auto'
    }

    const handleShowAssignSubjectModal = () => {
        setAssignSubjectModal(true)
        theBody.style.overflow = 'hidden'
    }
    const handleHideAssignSubjectModal = () => {
        setAssignSubjectModal(false)
        theBody.style.overflow = 'auto'
    }

    const handleShowUnassignClassModal = () => {
        setUnassignClassModal(true)
        theBody.style.overflow = 'hidden'
    }
    const handleHideUnassignClassModal = () => {
        setUnassignClassModal(false)
        theBody.style.overflow = 'auto'
    }

    const handleShowUnassignSubjectModal = () => {
        setUnassignSubjectModal(true)
        theBody.style.overflow = 'hidden'
    }
    const handleHideUnassignSubjectModal = () => {
        setUnassignSubjectModal(false)
        theBody.style.overflow = 'auto'
    }

    function handleDropdown() {
        setDropdown(!dropdown)
    }

    function handleAssignClass() {
        handleHideAssignClassModal()
        // assignedClassesList.push(selected)
        // console.log(assignedClassesList)
        // console.log(assignedClassesList[0])
    }

    const history = useHistory();
    const handleNavigate = () => {
        history.push('/management/teacher');
    };

    return (
        <div className='w-full flex flex-col items-center justify-center py-24 md:py-9 px-8'>

            {/* Modal box begins */}

            <div className={(assignClassModal || assignSubjectModal || unassignClassModal || unassignSubjectModal) ? showBackg : hideBackg}></div>
            {assignClassModal && <AssignClassModal onClose={() => setAssignClassModal(false)} teacher={teacher} school={school} />}
            {assignSubjectModal && <AssignSubjectsModal onClose={() => setAssignSubjectModal(false)} teacher={teacher} school={school} />}

            {/* Unassign subject modal */}
            <div className={unassignSubjectModal ? showAssignClassModal : hideAssignClassModal}>
                <div className='flex justify-center flex-col items-center w-full'>
                    <div className='bg-white p-4 md:w-1/3 w-2/3'>
                        <div className='p-3 bg-[#EEEEFC] w-max rounded'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M18.3327 9.2333V9.99997C18.3317 11.797 17.7498 13.5455 16.6738 14.9848C15.5978 16.4241 14.0854 17.477 12.3621 17.9866C10.6389 18.4961 8.79707 18.4349 7.11141 17.8121C5.42575 17.1894 3.98656 16.0384 3.00848 14.5309C2.0304 13.0233 1.56584 11.24 1.68408 9.4469C1.80232 7.65377 2.49702 5.94691 3.66458 4.58086C4.83214 3.21482 6.41 2.26279 8.16284 1.86676C9.91568 1.47073 11.7496 1.65192 13.391 2.3833M18.3327 3.3333L9.99935 11.675L7.49935 9.17497" stroke="#8281CD" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <p className='font-semibold mt-3'>Unassign Subject</p>
                        <p className='mt-2 mb-6 text-sm text-gray-500'>Are you sure you want to unassign this subject?</p>


                        <button onClick={handleHideUnassignSubjectModal} className='text-sm text-blue-500 bg-white border border-gray-300 hover:bg-blue-100 ml-2 px-4 py-2 rounded-full'>
                            Cancel
                        </button>
                        <button className='text-sm bg-indigo-600 border border-gray-300 hover:bg-indigo-800 text-white ml-2 px-4 py-2 rounded-full'>
                            Unassign
                        </button>

                    </div>
                </div>
            </div>

            {/* Unassign class modal */}
            <div className={unassignClassModal ? showAssignClassModal : hideAssignClassModal}>

                <div className='flex justify-center flex-col items-center w-full'>
                    <div className='bg-white p-4 md:w-1/3 w-2/3'>
                        <div className='p-3 bg-[#EEEEFC] w-max rounded'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M18.3327 9.2333V9.99997C18.3317 11.797 17.7498 13.5455 16.6738 14.9848C15.5978 16.4241 14.0854 17.477 12.3621 17.9866C10.6389 18.4961 8.79707 18.4349 7.11141 17.8121C5.42575 17.1894 3.98656 16.0384 3.00848 14.5309C2.0304 13.0233 1.56584 11.24 1.68408 9.4469C1.80232 7.65377 2.49702 5.94691 3.66458 4.58086C4.83214 3.21482 6.41 2.26279 8.16284 1.86676C9.91568 1.47073 11.7496 1.65192 13.391 2.3833M18.3327 3.3333L9.99935 11.675L7.49935 9.17497" stroke="#8281CD" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <p className='font-semibold mt-3'>Unassign Class</p>
                        <p className='mt-2 mb-6 text-sm text-gray-500'>Are you sure you want to unassign this class?</p>


                        <button onClick={handleHideUnassignClassModal} className='text-sm text-blue-500 bg-white border border-gray-300 hover:bg-blue-100 ml-2 px-4 py-2 rounded-full'>
                            Cancel
                        </button>
                        <button onClick={handleHideUnassignClassModal} className='text-sm bg-indigo-600 border border-gray-300 hover:bg-indigo-800 text-white ml-2 px-4 py-2 rounded-full'>
                            Unassign
                        </button>

                    </div>
                </div>
            </div>

            {/* Modal boxes end */}
            <div className='bg-white w-full px-7 py-9 rounded-[40px]'>
                <BackButton history={history} />

                <div className='flex flex-wrap items-start justify-between mt-4'>
                    <div className='sm:w-max w-full sm:mb-0 mb-3'>
                        <div className='flex items-center'> <p className='inline text-2xl font-semibold'>{teacher.firstname} {teacher.lastname}</p> <button className='flex items-center justify-between bg-[#5FC3811A] text-[#5FC381] text-[14px] py-1 px-2 rounded-full ml-3'><p className='mr-1'>Active</p> <FiChevronDown /> </button>
                        </div>
                        <p className='text-[14px] mt-3'>{teacher.email}</p>
                        <p className='text-[14px] mt-3'>{teacher.mobile_number}</p>
                    </div>

                    <div className='flex flex-wrap items-center justify-end sm:w-max w-full'>
                        <button onClick={handleShowAssignClassModal} className='sm:w-max w-full bg-[#C5C5E8] hover:bg-indigo-300 text-[#3F3DA5] sm:mr-4 mr-0 font-medium text-sm px-4 py-2 rounded-full items-center justify-between'>
                            Assign Class
                            <svg className='inline ml-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M8 0.75C8.69 0.75 9.25 1.31 9.25 2V6.75H14C14.3315 6.75 14.6495 6.8817 14.8839 7.11612C15.1183 7.35054 15.25 7.66848 15.25 8C15.25 8.33152 15.1183 8.64946 14.8839 8.88388C14.6495 9.1183 14.3315 9.25 14 9.25H9.25V14C9.25 14.3315 9.1183 14.6495 8.88388 14.8839C8.64946 15.1183 8.33152 15.25 8 15.25C7.66848 15.25 7.35054 15.1183 7.11612 14.8839C6.8817 14.6495 6.75 14.3315 6.75 14V9.25H2C1.66848 9.25 1.35054 9.1183 1.11612 8.88388C0.881696 8.64946 0.75 8.33152 0.75 8C0.75 7.66848 0.881696 7.35054 1.11612 7.11612C1.35054 6.8817 1.66848 6.75 2 6.75H6.75V2C6.75 1.31 7.31 0.75 8 0.75Z" fill="#3F3DA5" /></svg>
                        </button>

                        <button onClick={handleShowAssignSubjectModal} className='sm:w-max w-full sm:mt-0 mt-3 bg-[#4543B5] hover:bg-indigo-900 text-white font-medium text-sm px-4 py-2 rounded-full items-center justify-between'>
                            Assign Subject
                            <svg className='inline ml-2' xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                                <path d="M8 0.330078C8.69 0.330078 9.25 0.890078 9.25 1.58008V6.33008H14C14.3315 6.33008 14.6495 6.46177 14.8839 6.69619C15.1183 6.93061 15.25 7.24856 15.25 7.58008C15.25 7.9116 15.1183 8.22954 14.8839 8.46396C14.6495 8.69838 14.3315 8.83008 14 8.83008H9.25V13.5801C9.25 13.9116 9.1183 14.2295 8.88388 14.464C8.64946 14.6984 8.33152 14.8301 8 14.8301C7.66848 14.8301 7.35054 14.6984 7.11612 14.464C6.8817 14.2295 6.75 13.9116 6.75 13.5801V8.83008H2C1.66848 8.83008 1.35054 8.69838 1.11612 8.46396C0.881696 8.22954 0.75 7.9116 0.75 7.58008C0.75 7.24856 0.881696 6.93061 1.11612 6.69619C1.35054 6.46177 1.66848 6.33008 2 6.33008H6.75V1.58008C6.75 0.890078 7.31 0.330078 8 0.330078Z" fill="white" /></svg>
                        </button>
                    </div>
                </div>

            </div>

            <div className='bg-white w-full rounded-[40px] p-6 mt-4'>
                <div className='flex flex-wrap items-center'>
                    <button onClick={() => {
                        setBoard('subjects')
                    }} className={board === 'classes' ? 'sm:w-max w-full bg-white hover:bg-indigo-300 border border-[#C5C5E8] text-[#3F3DA5] mr-4 font-semibold text-sm px-4 py-2 rounded-full items-center justify-between'
                        : 'sm:w-max w-full bg-[#C5C5E8] hover:bg-indigo-300 border border-[#C5C5E8] text-[#3F3DA5] mr-4 font-semibold text-sm px-4 py-2 rounded-full items-center justify-between'
                    }>
                        Assigned Subjects
                    </button>

                    <button onClick={() => { setBoard('classes') }} className={board === 'subjects' ? 'sm:w-max w-full mt-3 sm:mt-0 bg-white hover:bg-indigo-300 border border-[#C5C5E8] text-[#3F3DA5] mr-4 font-semibold text-sm px-4 py-2 rounded-full items-center justify-between'
                        : 'sm:w-max w-full mt-3 sm:mt-0 bg-[#C5C5E8] hover:bg-indigo-300 border border-[#C5C5E8] text-[#3F3DA5] mr-4 font-semibold text-sm px-4 py-2 rounded-full items-center justify-between'
                    }>
                        Assigned Classes
                    </button>
                </div>
                { board === 'classes' ?
                    <ClassesSection teacher={teacher} school={school} />
                    :
                    <SubjectsSection teacher={teacher} school={school} />
                }
            </div>
        </div>
    );
}
