import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { MdOutlineCancel } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import { links, linksTeacher } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import logo from "../data/logo/logo.png";

const Sidebar = () => {
  const { currentColor, activeMenu, setActiveMenu, screenSize, user } = useStateContext();

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const activeLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 font-bold rounded-lg bg-indigo-100 text-indigo-700 text-md m-2';
  const normalLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 font-semibold rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link to="/" onClick={handleCloseSideBar} className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
              
              <img src={logo} alt="" width="180" />
            </Link>
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                style={{ color: currentColor }}
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          <div className="mt-10">
            {user?.is_school ? (
              links.map((item) => (
                <div key={item.title}>
                  <p className="text-gray-400 text-xs dark:text-gray-400 m-3 mt-4 uppercase">
                    {item.title}
                  </p>
                  {item.links.map((link) => (
                    <NavLink
                      to={`/${link.url}`}
                      key={link.url}
                      onClick={handleCloseSideBar}
                      /*style={( isActive ) => ({
                        backgroundColor: isActive ? "bg-blue-800" : '',
                      })}*/
                      className={( isActive ) => (isActive ? activeLink : normalLink)}
                    >
                      {link.icon}
                      <span className="capitalize">{link.name}</span>
                    </NavLink>
                  ))}
                </div>
              ))
            ) : (
              linksTeacher.map((item) => (
                <div key={item.title}>
                  <p className="text-gray-400 text-xs dark:text-gray-400 m-3 mt-4 uppercase">
                    {item.title}
                  </p>
                  {item.links.map((link) => (
                    <NavLink
                      to={`/${link.url}`}
                      key={link.url}
                      onClick={handleCloseSideBar}
                      /*style={( isActive ) => ({
                        backgroundColor: isActive ? "bg-blue-800" : '',
                      })}*/
                      className={( isActive ) => (isActive ? activeLink : normalLink)}
                    >
                      {link.icon}
                      <span className="capitalize">{link.name}</span>
                    </NavLink>
                  ))}
                </div>
              ))
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
