"use client";
import React, { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";

const PasswordInput = ({
  id,
  label,
  placeholder = "Enter your password",
  value,
  onChange,
  onBlur,
  errorMessage = "",
  required = false,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  return (
    <div className="mb-6 relative">
      {label && (
        <label htmlFor={id} className="block font-semibold text-sm mb-1">
          {label}
        </label>
      )}
      <div className="relative">
        <input
          type={passwordVisible ? "text" : "password"}
          id={id}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          required={required}
          className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
        />
        <i className="fal fa-lock"></i>
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute right-3 top-2/4 transform -translate-y-1/2 text-gray-500"
        >
          {passwordVisible ? <FiEyeOff /> : <FiEye />}
        </button>
      </div>
      {errorMessage && (
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">
          <span className="font-medium">Oops!</span> {errorMessage}
        </p>
      )}
    </div>
  );
};

export default PasswordInput;
