import { TaggedInput, TaggedSelect } from "./ResultsComponents"
import { useState, useEffect } from "react"
import { Toggle } from "./ResultsComponents"
export default function RemarksAndMore({ setFinalState }){
  const [sampleRemarks, setSampleRemarks] = useState([])
  const [skills, setSkills] = useState([])
  const [abilities, setAbilities] = useState([])
  const [enableRemark, setEnableRemark] = useState(true)
  const [enableComment, setEnableComment] = useState(true)

  const handleChange = () => {
    console.log("There was a change")
    const newFinal = {
      remarksList: sampleRemarks,
      abilitiesList: abilities, 
      skillsList: skills,
      remarks: enableRemark,
      comments: enableComment
    }

    setFinalState(newFinal)
  }

  useEffect(handleChange, [skills, abilities, sampleRemarks, enableComment, enableRemark])
  return(
    <div>
      <div className="flex justify-between gap-3">
        <p className="font-semibold">Enable teachers remark</p>
        <Toggle toggle={enableRemark} setToggle={setEnableRemark} />
      </div>

      <TaggedInput 
      label="Enter sample remarks (optional)"
      placeholder={`Enter remarks (press the enter key after each remark you add)`}
      tags={sampleRemarks}
      setTags={setSampleRemarks}/>

      <div className="flex justify-between gap-3">
        <p className="font-semibold">Enable General comment</p>
        <Toggle toggle={enableComment} setToggle={setEnableComment}/>
      </div>

      <TaggedSelect 
        label="Add Skills and traits options to show on report"
        placeholder="Select skills"
        listItems={[
          "Punctuality", 
          "Politeness", 
          "Neatness", 
          "Honesty", 
          "Leadership Skill", 
          "Cooperation", 
          "Attentiveness", 
          "Perseverance", 
          "Attitude to Work", 
          "Handwriting", 
          "Verbal Fluency", 
          "Sports", 
          "Handling Tools", 
          "Drawing & Painting",
          "Communication"
        ]}
        tags={skills}
        setTags={setSkills}
      />

      <TaggedSelect 
      label="Set ratings for abilities"
      placeholder="Select rating"
      listItems={["Excellent", "Good", "Fair", "Needs Improvement"]}
      tags={abilities}
      setTags={setAbilities}
      />
    </div>
  )
}