import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { userRequest } from '../utils/requestMethods';
import { FiEdit, FiMoreHorizontal } from 'react-icons/fi';
import { ButtonLoading, Header, CustomPagination, StatusBadge, BackButton, AddStudentDetailsModal, SkeletonTable, TableDropdown } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import * as Icons from '../data/class';
import { MdSearch } from 'react-icons/md';
import { exportToCSV } from '../utils/exportCSV';

const customStyles = {
  headRow: {
    style: {
      backgroundColor: 'rgb(248, 250, 252)',
      fontWeight: "bold",
    },
  },
  hidden: {
    style: {
      display: 'none',
    },
  }
};

const Students = ({ history }) => {
  const location = useLocation();
  const classId = location.pathname.split('/')[2];
  //console.log(classId)
  const { school } = useStateContext();
  const schoolId = school?._id;
  const schoolCode = school?.code;
  const [students, setStudents] = useState([]);
  const [activeStudents, setActiveStudents] = useState(0);
  const [inactiveStudents, setInactiveStudents] = useState(0);
  const [classInfo, setClassInfo] = useState({});
  //const [clicked, setClicked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [accountSuccess, setAccountSuccess] = useState(false);
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState(null);
  const [filterText, setFilterText] = useState('');

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    fetchStudents();
    fetchClass();
  }, [classId, schoolId]);

  const fetchStudents = async () => {
    try {
      setLoading(true);
      const { data } = await userRequest.get(`children/class/${classId}?limit=200`);
      console.log(data.children);
      setStudents(data.children);
      // Calculate active and inactive students
      const active = data.children.filter(student => student.academic_status === 'active' && student.is_active);
      const inactive = data.children.filter(student => student.academic_status !== 'active' || !student.is_active);

      setActiveStudents(active.length);
      setInactiveStudents(inactive.length);
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const fetchClass = async () => {
    try {
      //setLoading(true);
      const { data } = await userRequest.get(`classes/${schoolId}/${classId}`);
      //console.log(data);
      setClassInfo(data);
      //setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const ids = students?.map(child => child._id);
  //console.log(ids);

  const deleteHandler = async (studentId) => {
    try {
      const res = await userRequest.delete(`children/${studentId}`);
      //console.log(res.data);
      setStudents(res.data.classes)
    } catch (error) {
      console.log(error);
    }
  };

  const handleDropdownClick = (rowIndex, event) => {
    const rect = event.target.getBoundingClientRect();
    setDropdownIndex(dropdownIndex === rowIndex ? null : rowIndex);
    setDropdownPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
  };

  const handleCloseDropdown = () => setDropdownIndex(null);
  
  const columns = [
    {
      field: "Name",
      name: "First Name",
      cell: row => row.firstname,
    },
    {
      field: "Name",
      name: "Last Name",
      cell: row => row.lastname,
    },
    {
      field: "gender",
      name: "Gender",
      cell: row => row.gender,
    },
    {
      name: "Account (collection)",
      selector: row => (
        <>
          {row.account ? (
            <div>
              <p>{row.account.account_number}</p>
              <p>{row.account.name}</p>
            </div>
          ) : (
            <p>Account not assigned</p>
          )}
        </>
      ),
    },
    {
      name: "Status",
      selector: row => (
        <div>
          {row.is_active ? (
            <StatusBadge status="active" />
          ) : (
            <StatusBadge status="inactive" />
          )}
        </div>
      ),
    },
    {
      // Visually hidden column
      name: "Account (collection)",
      cell: row =>
        row.account
          ? `${row.account.name} (${row.account.account_number})`
          : "No account",
      hidden: true,
    },
    {
      cell: (row, rowIndex) => (
        <div className="relative">
          <button
            className="p-2 text-gray-600 hover:text-gray-900"
            onClick={e => handleDropdownClick(rowIndex, e)}
          >
            <FiMoreHorizontal className="text-2xl" />
          </button>
          <TableDropdown
            isVisible={dropdownIndex === rowIndex}
            position={dropdownPosition}
            options={[
              {
                label: "View Students",
                icon: <img src={Icons.checkList} alt="Payout Icon" />,
                onClick: () => {
                  history.push(`/students/view/${row._id}/${classId}`, {
                    student: `${row.firstname} ${row.lastname}`,
                  });
                },
              },
              {
                label: "Edit Student",
                icon: <img src={Icons.pencilEdit} alt="Payout Icon" />,
                onClick: () => {
                  history.push(`/update-student/${row._id}`, { student: row });
                },
              },
            ]}
            onClose={handleCloseDropdown}
          />
        </div>
      ),
      width: "120px",
    },
  ];
  

  const filteredData = students?.filter((item) =>
    (item.firstname?.toLowerCase().includes(filterText.toLowerCase()) || '') ||
    (item.lastname?.toLowerCase().includes(filterText.toLowerCase()) || '') 
  );

  const accountHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!ids) {
      setError("Student Info Still loading!");
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
      return;
    }

    try {
      await userRequest.post("/children/assign-account", 
      {
        school_code: schoolCode,
        class_id: classId,
        student_ids: ids
      });
      setAccountSuccess(true);
      fetchStudents();
      setTimeout(() => {
        setAccountSuccess(false);
      }, 5000);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
    <div className="px-10 pt-6">
      <BackButton history={history} />
    </div>
    <div className="px-10 pt-5">
      <div className="flex flex-wrap justify-between items-center mb-4">
        <Header category="Students" title={classInfo?.class_name || "Loading..."} />
        <div className="flex flex-wrap justify-center md:justify-end w-full md:w-auto">
          <button type="button" onClick={toggleModal} className="inline-flex justify-center py-2 px-4 mb-2 md:mb-0 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-[#3F3DA5] hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2">
            Add Student Details
          </button>
          {loading ? (
            <ButtonLoading styles="inline-flex justify-center py-2 px-4 mx-2 mb-2 md:mb-0 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-[#3F3DA5]" />
          ) : (
            <button type="button" onClick={accountHandler} className="inline-flex justify-center py-2 px-4 mx-2 mb-2 md:mb-0 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-[#3F3DA5] hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2">
              Assign Accounts
            </button>
          )}
        </div>
      </div>
    </div>
    
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 mb-2 m-2 md:m-2 mt-5 p-2 md:p-5">
      <div className="bg-white border-2 border-gray-200 dark:text-gray-200 dark:bg-secondary-dark-bg h-34 rounded-3xl p-8 pt-9 m-3">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-gray-400 text-sm">Total Student</p>
            <p className="text-2xl font-semibold">{students.length}</p>
          </div>
          <button type="button" className="bg-[#3371E8] text-white text-2xl opacity-90 text-white hover:drop-shadow-xl rounded-xl py-4 px-4">
            <img src={Icons.userMultiple} alt="Wallet Icon" />
          </button>
        </div>
      </div>

      <div className="bg-white border-2 border-gray-200 dark:text-gray-200 dark:bg-secondary-dark-bg h-34 rounded-3xl p-8 pt-9 m-3">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-gray-400 text-sm">Active Students</p>
            <p className="text-2xl font-semibold">{activeStudents}</p>
          </div>
          <button type="button" className="bg-[#41D3BD] text-white text-2xl opacity-90 text-white hover:drop-shadow-xl rounded-xl py-4 px-4">
            <img src={Icons.coupon} alt="Payout Icon" />
          </button>
        </div>
      </div>

      <div className="bg-white border-2 border-gray-200 dark:text-gray-200 dark:bg-secondary-dark-bg h-34 rounded-3xl p-8 pt-9 m-3">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-gray-400 text-sm">Inactive Students</p>
            <p className="text-2xl font-semibold">{inactiveStudents}</p>
          </div>
          <button type="button" className="bg-[#E26E6A] text-white text-2xl opacity-90 text-white hover:drop-shadow-xl rounded-xl py-4 px-4">
            <img src={Icons.taskDaily} alt="Cashflow Icon" />
          </button>
        </div>
      </div>
    </div>

    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl dark:bg-secondary-dark-bg">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-lg font-semibold w-44">Students Details</h1>
        <div className="flex justify-center md:justify-end w-full">
          <form className="max-w-lg">
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center pl-4 pointer-events-none">
                <MdSearch />
              </div>
              <input
                type="text"
                id="email-address-icon"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                placeholder="Search"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
              />
            </div>
          </form>
          <button type="button" onClick={() => exportToCSV(filteredData, columns, `${classInfo?.class_name || 'Students'}_export.csv`)} className="inline-flex justify-center py-2 px-6 mx-2 mb-2 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2">
            Export
          </button>
        </div>
      </div>
      <div className="border rounded-3xl">
        <DataTable
          data={filteredData}
          columns={columns.filter(col => !col.hidden)}
          getRowId={(row) => row._id}
          pagination
          paginationComponent={CustomPagination}
          paginationRowsPerPageOptions={[10, 20, 30, 50]}
          customStyles={customStyles}
          progressPending={loading}
          progressComponent={<SkeletonTable rows={5} columns={6} />}
          highlightOnHover
          striped
        />
      </div>
      {isModalOpen && 
        <AddStudentDetailsModal 
          closeModal={toggleModal} 
          onStudentCreated={fetchStudents}
          schoolId={schoolId}
          schoolCode={schoolCode}
          classId={classId}
        />
      }
    </div>
    </>
  );
};
export default Students;
