import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { userRequest } from '../../../utils/requestMethods';
import { Header, CustomPagination, SkeletonTable } from '../../../components';
import { useStateContext } from '../../../contexts/ContextProvider';
import { MdSearch } from 'react-icons/md';

const customStyles = {
  headRow: {
    style: {
      backgroundColor: 'rgb(248, 250, 252)',
      fontWeight: "bold",
    },
  },
};

const TeacherSubjects = ({ history }) => {
  const { user, school } = useStateContext();
  const [subjects, setSubjects] = useState([]);
  //const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState('');

  const fetchSubjects = async () => {
    setLoading(true);
    try {
      const { data } = await userRequest.get(`/subjects/assigned/${school?._id}/${user?._id}?limit=100`);
      //console.log(data);
      setSubjects(data.subjects || []);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubjects();
  }, [school?._id, user?._id]);
  
  const columns = [
    {
      name: "Subject Name",
      selector: row => row.subject_name
    },
    {
      name: "Description",
      selector: row => row.subject_description
    },
    {
      name: "Subject Code",
      selector: row => row.subject_code
    },
    {
      name: "Class",
      selector: row => row.class.class_name,
    },
    {
      name: "Lesson Notes",
      selector: row => row.lesson_note.length,
    },
    {
      name: 'Action',
      cell: (row) => (
        <Link to={{ pathname: `/t/subjects/notes/${row._id}/${row.class._id}`, state: { subject: row } }}  className="font-medium text-[#00509D] hover:underline">View Details</Link>
      ),
      ignoreRowClick: true,
      button: true,
    },
  ];

  const filteredData = subjects?.filter((item) =>
    (item.subject_name?.toLowerCase().includes(filterText.toLowerCase()) || '') ||
    (item.subject_description?.toLowerCase().includes(filterText.toLowerCase()) || '') ||
    (item.class?.class_name?.toLowerCase().includes(filterText.toLowerCase()) || '')
  );

  return (
    <>
    <div className="px-3 md:px-10 pt-10 md:pt-5 mt-10">
      <div className="flex flex-wrap justify-between items-center mb-4">
        <Header category="Subjects" title="Assigned Lesson" />
      </div>
    </div>

    <div className="m-2 md:mx-10 p-4 md:p-10 bg-white rounded-3xl dark:bg-secondary-dark-bg min-h-screen">
      <div className="flex flex-wrap justify-between items-center mb-4">
        <h1 className="text-lg font-semibold w-full md:w-auto mb-2 md:mb-0" style={{ maxWidth: "20em" }}>
          Subject Details
        </h1>
        <div className="flex flex-wrap justify-center md:justify-end w-full md:w-auto gap-2">
          {/* Search Input */}
          <form className="w-full md:w-auto">
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center pl-4 pointer-events-none">
                <MdSearch />
              </div>
              <input type="text" value={filterText} onChange={(e) => setFilterText(e.target.value)} className="w-full sm:w-auto bg-gray-50 border border-gray-300 text-gray-900 text-sm sm:text-md rounded-full focus:ring-blue-500 focus:border-blue-500 block pl-8 p-2.5" placeholder="Search"/>
            </div>
          </form>
        </div>
      </div>
      <div className="border rounded-3xl">
        <DataTable
          data={filteredData}
          columns={columns}
          getRowId={(row) => row._id}
          pagination
          paginationComponentOptions={{
            rowsPerPageText: 'Rows per page:',
            rangeSeparatorText: 'of',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'All',
          }}
          paginationComponent={CustomPagination}
          paginationRowsPerPageOptions={[10, 20, 30, 50]}
          customStyles={customStyles}
          progressPending={loading}
          progressComponent={<SkeletonTable rows={5} columns={6} />}
          highlightOnHover
          striped
        />
      </div>
    </div>
    </>
  );
};
export default TeacherSubjects;
