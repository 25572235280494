import { Toggle } from "./ResultsComponents"
export default function GeneralCustomization({
  review, 
  preview, 
  customize, 
  setReview, 
  setPreview,
  setCustomize}){
  return(
    <div>
            <div className="flex justify-between gap-2 my-10">
              <p className="font-semibold">Enable preview option for teachers</p>
              <div className="w-6">
                <Toggle toggle={preview} setToggle={setPreview}/>
              </div>
            </div>

            <div className="flex justify-between gap-2 my-10">
              <p className="font-semibold">Admin must review all results</p>
              <div className="w-6">
                <Toggle toggle={review} setToggle={setReview}/>
              </div>
            </div>

            <div className="flex justify-between gap-2 my-10">
              <p className="font-semibold">Teachers should customize result format (e.g CA weight adjustment)</p>
              <div className="w-6">
                <Toggle toggle={customize} setToggle={setCustomize}/>
              </div>
            </div>
          </div>
  )
}