import { FiCheckCircle, FiXCircle, FiX } from "react-icons/fi";

const AlertNotification = ({ type, message, onClose }) => {
  // Define dynamic styles and icons based on the type
  const isSuccess = type === "success";
  const icon = isSuccess ? <FiCheckCircle size={24} /> : <FiXCircle size={24} />;
  const color = isSuccess ? "text-green-600" : "text-red-600";

  return (
    <div
      className={`fixed top-20 right-6 sm:right-6 sm:left-auto flex items-center bg-white shadow-md rounded-lg px-4 py-2 border ${
        isSuccess ? "border-green-200" : "border-red-200"
      } z-50 max-w-[90%] sm:max-w-sm`}
    >
      {/* Icon */}
      <div className={`${color} flex-shrink-0`}>{icon}</div>

      {/* Message */}
      <span className={`ml-3 ${color} font-semibold text-sm sm:text-base`}>
        {message}
      </span>

      {/* Close Icon */}
      <button
        onClick={onClose}
        className="ml-auto text-gray-600 hover:text-gray-800"
      >
        <FiX size={20} />
      </button>
    </div>
  );
};

export default AlertNotification;
