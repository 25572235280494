import React, { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { userRequest } from '../../../utils/requestMethods';
import { BackButton, CustomPagination, SkeletonTable, StatusBadge } from '../../../components';
import { useStateContext } from '../../../contexts/ContextProvider';
import EditResultDetailsModal from "../../../components/teacherModule/editResult/EditResultDetailsModal";
import AddCommentModal from "../../../components/teacherModule/results/AddCommentModal";

const customStyles = {
    headRow: {
        style: {
            backgroundColor: 'rgb(248, 250, 252)',
            fontWeight: "bold",
        },
    },
};

const ViewStudentResult = ({ history }) => {
    const location = useLocation();
    const studentId = location.pathname.split('/')[4];
    const student = location.state?.student;
    const classId = student.class_info._id;
    const { user, school } = useStateContext();
    const schoolTerm = school?.settings?.current_term;
    const schoolSession = school?.settings?.current_session;
    const [subjects, setSubjects] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);

    const toggleResultModal = (subject = null) => {
        setSelectedSubject(subject);
        setIsModalOpen(!isModalOpen);
    };

    const toggleCommentModal = () => {
        setIsCommentModalOpen(!isCommentModalOpen);
    };

    const fetchSubjects = async () => {
        setLoading(true);
        try {
            const { data } = await userRequest.get(`result/${school?._id}/${studentId}?term=${schoolTerm}&session=${schoolSession}`);
            console.log(data);
            setSubjects(data.results || []);
        } catch (error) {
            console.error("Error fetching subjects:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSubjects();
    }, [school?._id, user?._id]);

    const columns = [
        {
            name: "Subject Name",
            selector: row => row.subject.name,
        },
        {
            name: `CA Score (${school?.settings?.assessment?.ca?.max_score})`,
            selector: row => 
                Array.isArray(row?.cas) 
                    ? row.cas.reduce((sum, item) => sum + item.score, 0) 
                    : 0,
        },        
        {
            name: `Exam Scrore (${school?.settings?.assessment?.exam?.max_score})`,
            selector: row => row.exam,
        },
        {
            name: "Total",
            selector: row => row.total,
        },
        {
            name: "Percentage (%)",
            selector: row => row.total,
        },
        {
            name: "Status",
            selector: row => (
              <div>
                  <StatusBadge status="active" label="Uploaded" />
              </div>
            )
          },
        {
            name: 'Action',
            cell: (row) => (
                <button onClick={() => toggleResultModal(row)} className="font-medium text-[#00509D] hover:underline">
                    Edit
                </button>
            ),
            ignoreRowClick: true,
            button: true,
            width: "200px",
        },
    ];

    return (
        <div className="w-full flex items-center justify-center py-14 md:py-8 relative">
            <div className="bg-transparent w-full pb-16 px-3 md:px-9 py-8 sm:py-0">
                <div className="bg-white rounded-2xl p-6">
                    <BackButton history={history} />
                    <div className="w-14 h-14 rounded-full bg-blue-500 mb-4 mt-4 border-[3px] border-[#D8846E]"></div>
                    <div className="flex flex-wrap items-center justify-between">
                        <div className="w-full sm:w-max mb-4 sm:mb-0">
                            <p className="text-2xl font-semibold mb-2">{student?.firstname} {student?.lastname}</p>
                            <p className="text-sm text-[#606060] font-medium">
                                {school?.settings.current_term} Term - {school?.settings.current_session}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="mt-10 p-5 md:p-10 bg-white rounded-3xl dark:bg-secondary-dark-bg">
                    <div className="flex flex-wrap justify-between items-center mb-4">
                        <h1 className="text-lg font-semibold w-full md:w-auto mb-2 md:mb-0" style={{ maxWidth: "20em" }}>
                            Student Result
                        </h1>
                        <div className="flex flex-wrap justify-center md:justify-end w-full md:w-auto gap-2">
                            <button type="button" onClick={toggleCommentModal} className="sm:w-max w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-full bg-indigo-100 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2">
                                Add General Review
                            </button>
                            <Link to={{ pathname: `/t/results/upload/${studentId}`, state: { student: student } }} className="sm:w-max w-full">
                                <button type="button" className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2">
                                    Upload Student Result
                                </button>
                            </Link>
                        </div>
                    </div>

                    <div className="border rounded-3xl">
                        <DataTable
                            data={subjects}
                            columns={columns}
                            getRowId={(row) => row._id}
                            pagination
                            paginationComponentOptions={{
                                rowsPerPageText: 'Rows per page:',
                                rangeSeparatorText: 'of',
                                selectAllRowsItem: true,
                                selectAllRowsItemText: 'All',
                            }}
                            paginationComponent={CustomPagination}
                            paginationRowsPerPageOptions={[10, 20, 30, 50]}
                            customStyles={customStyles}
                            progressPending={loading}
                            progressComponent={<SkeletonTable rows={5} columns={6} />}
                            highlightOnHover
                            striped
                        />
                    </div>
                </div>
            </div>
            {isModalOpen && 
                <EditResultDetailsModal
                    closeModal={toggleResultModal} 
                    onResultEdited={fetchSubjects}
                    childId={student._id}
                    teacherId={user._id}
                    school={school}
                    classId={classId}
                    subject={selectedSubject}
                />
            }
            {isCommentModalOpen && 
                <AddCommentModal
                    closeModal={toggleCommentModal} 
                    onCommentAdded={fetchSubjects}
                    childId={student._id}
                    teacherId={user._id}
                    school={school}
                    classId={classId}
                />
            }
        </div>
    );
};

export default ViewStudentResult;
