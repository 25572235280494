import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { AddTermModal } from '../components';
import document from '../data/icons/documents.svg'
import withdraw from '../data/icons/withdraw.svg'
import support from '../data/icons/support.svg'
import bgImage from '../data/74f84ef2d6c5d6641ebe23f75ead2ca7.png';


const GetStarted = () => {
  const { user, school } = useStateContext();
  //console.log(currentColor);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  
  return (
    <>
    <section className="dark:bg-gray-900">
      <div className="py-8 px-8 mx-auto lg:py-16 mt-10 lg:mt-0">
        <div className="bg-[#1D1C4C] text-white border rounded-3xl p-6 md:p-12 mb-8 relative overflow-hidden">
          <div className="absolute inset-0 bg-cover bg-center opacity-50" style={{ backgroundImage: `url(${bgImage})` }}></div>
          <div className="absolute inset-0 bg-black opacity-40"></div>
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center relative z-5 space-y-4 md:space-y-0">
            <div className="w-full md:w-2/3">
              <h1 className="text-white text-xl md:text-2xl font-extrabold mb-2">
                Welcome Back, {user?.firstname}
              </h1>
              <p className="text-sm md:text-lg font-normal">
                Welcome back {user?.firstname}, please make sure you’ve completed your setup for the term and <br />
                enjoy the seamless experience
              </p>
            </div>
            <button onClick={toggleModal} className="text-white bg-[#4543B5] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-4 py-2.5 md:px-5 md:py-2.5 text-center">
              Setup Term <i className="fad fa-arrow-right mt-1 ml-2"></i>
            </button>
          </div>
        </div>
        <div className="bg-white border rounded-3xl">
          <h1 className="text-xl font-semibold mb-2 px-9 py-4">Complete Setup</h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 mb-2 px-8 py-6">
            <div className="w-full bg-white pb-10 px-4 border-2 border-gray-200 rounded-3xl dark:bg-gray-800 dark:border-gray-700">
              <div className="flex flex-col items-center text-center pt-10 px-4">
                <img className="h-20 w-20" src={document} alt="document"/>
                <span className="text-sm text-gray-500 dark:text-gray-400 p-4">Submit Compliance documents to complete business registration</span>
              </div>
              {school?.kyc_progress < 8 ? (
                <Link to="/onboarding/get-started/documents">
                  <button className="w-full text-white bg-[#4543B5] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center">
                    Continue
                  </button>
                </Link>
              ) : (
                <button className="w-full text-indigo-800 bg-indigo-100 border border-gray-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center">
                  Completed
                </button>
              )}
            </div>

            <div className="w-full bg-white pb-10 px-4 border-2 border-gray-200 rounded-3xl dark:bg-gray-800 dark:border-gray-700">
              <div className="flex flex-col items-center text-center pt-10 px-4">
                <img className="rounded-3xl h-20 w-20" src={withdraw} alt="document"/>
                <span className="text-sm text-[#606060] dark:text-gray-400 p-4">Setup your business withdrawal account and withdrawal frequency</span>
              </div>
              {school?.kyc_progress !== 8 ? (
                <button className="w-full text-white bg-[#4543b5cf] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center">
                  Pending
                </button>
              ) : (
                <Link to="/compliance/payment">
                  <button className="w-full text-white bg-[#4543B5] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center">
                    Continue
                  </button>
                </Link>
              )}
            </div>

            <div className="w-full bg-[#E26E6A] text-white pb-10 px-4 border-2 border-gray-200 rounded-3xl">
              <div className="flex flex-col items-center text-center pt-10 px-4">
                <img className="h-20 w-25" src={support} alt="document"/>
                <span className="text-sm text-gray-500 dark:text-gray-400 p-4">Find answers to your questions and get in touch with our support staff</span>
              </div>
              <Link to="/support">
                <button className="w-full text-gray-900 focus:outline-none bg-white hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 font-medium rounded-3xl text-sm px-5 py-2.5 text-center">
                  Get Support
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && 
        <AddTermModal
          closeModal={toggleModal}
          schoolId={school?._id}
          school={school}
        />
      }
    </section>
    </>
  );
};

export default GetStarted;
