import React, { useState } from "react";
import { MdClose, MdOutlineCalendarToday } from "react-icons/md";
import { userRequest } from "../../utils/requestMethods";
import AlertNotification from "../common/AlertNotification";

const AddTeacherModal = ({ onClose, schoolId, onTeacherAdded }) => {
  const [formData, setFormData] = useState({
    email: "",
    gender: "",
    mobile_number: "",
    date_of_emp: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    /*if (!name || !lastname || !gender) {
      setError("Please Fill all the Feilds");
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
      return;
    }*/
    try {
      await userRequest.post(`/schools/invite/${schoolId}`, formData);
      //console.log(data);
      setSuccess("Invite sent to teacher successfully!");
      setFormData({ email: "", gender: "", mobile_number: "", date_of_emp: "" });
      onTeacherAdded();
      setTimeout(() => {
        onClose();
      }, 3000);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
      setTimeout(() => {
        setError("");
      }, 5000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
      <div className="bg-white rounded-3xl w-full max-w-md p-6 shadow-lg relative">
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Send Invite</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700 hover:bg-gray-200 text-2xl">
            <MdClose />
          </button>
        </div>

        <p className="text-gray-600 mb-6 text-sm">
          Provide details of the teacher you will like to send invites to.
        </p>

        {/* Form */}
        <form onSubmit={submitHandler} className="space-y-4">
          {error &&
          <div className="p-4 mb-4 text-sm text-white rounded-lg bg-red-500" role="alert">
              <span className="font-medium">Error!</span> {error}
          </div>}
          {success &&
          <div className="p-4 mb-4 text-sm text-white rounded-lg bg-green-500" role="alert">
          <span className="font-medium">Success!</span> {success}
          </div>}
          {/* Email Input */}
          <div>
            <label className="block text-sm text-gray-600 mb-1">Email Address</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter email"
              className="w-full border-2 rounded-lg p-2 text-sm focus:ring-2 focus:ring-indigo-500 outline-none"
              required
            />
          </div>

          {/* Gender Input */}
          <div>
            <label className="block text-sm text-gray-600 mb-1">Gender</label>
            <select
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              className="w-full border rounded-lg p-2 text-sm focus:ring-2 focus:ring-indigo-500 outline-none"
              required
            >
              <option value="">Select gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>

          {/* Mobile Number Input */}
          <div>
            <label className="block text-sm text-gray-600 mb-1">Mobile Number</label>
            <input
              type="tel"
              name="mobile_number"
              value={formData.mobile_number}
              onChange={handleChange}
              placeholder="+234XXXXXXXXXX"
              className="w-full border rounded-lg p-2 text-sm focus:ring-2 focus:ring-indigo-500 outline-none"
              required
            />
          </div>

          {/* Date of Employment Input */}
          <div>
            <label className="block text-sm text-gray-600 mb-1">Date of Employment</label>
            <div className="relative">
              <input
                type="date"
                name="date_of_emp"
                value={formData.date_of_emp}
                onChange={handleChange}
                className="w-full border rounded-lg p-2 text-sm focus:ring-2 focus:ring-indigo-500 outline-none"
                required
              />
              <MdOutlineCalendarToday className="absolute right-3 top-3 text-gray-400" />
            </div>
          </div>
          {/* Submit Button */}
          <div className="mt-4">
            <button
              type="submit"
              disabled={loading}
              className="w-full bg-[#3F3DA5] text-white font-semibold rounded-full p-2 hover:bg-indigo-800 transition disabled:opacity-50"
            >
              {loading ? "Submitting..." : "Proceed"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTeacherModal;
