import React, { useState } from 'react';
import { userRequest } from '../../../utils/requestMethods';

const EditCAModal = ({
  subject,
  school,
  schoolId,
  teacherId,
  childId,
  subjectId,
  classId,
  goback,
  onResultEdited,
}) => {
  const [formData, setFormData] = useState({
    caScores: subject.cas.map((ca) => ca.score || 0), // Initialize scores from the subject.cas array
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const updatedScores = [...formData.caScores];
    updatedScores[index] = parseInt(value, 10) || 0; // Update the specific CA score
    setFormData({ ...formData, caScores: updatedScores });
  };

  const validateScores = () => {
    return school?.settings?.assessment?.ca?.cas.every(
      (ca, index) => formData.caScores[index] <= ca.max_score
    );
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!validateScores()) {
      setError('One or more CA scores exceed the maximum allowed score.');
      setTimeout(() => setError(''), 5000);
      return;
    }

    setLoading(true);

    try {
      // Prepare the payload
      const payload = {
        childId,
        schoolId,
        session: school?.settings?.current_session,
        term: school?.settings?.current_term,
        classId,
        subjectId: subject.subject.id, // Ensure the correct subjectId is used
        results: subject.cas.map((ca, index) => ({
          ca: ca.ca, // Use the CA number from the original structure
          score: formData.caScores[index], // Updated score from form
        })),
      };

      // Send the payload to the server
      await userRequest.put('/result/ca', payload);

      setSuccess(true);
      onResultEdited();
      setTimeout(() => setSuccess(false), 2000);
    } catch (error) {
      console.error(error);
      setError('An error occurred while saving the CA scores.');
      setTimeout(() => setError(''), 5000);
    }

    setLoading(false);
  };

  return (
    <div className="mt-4">
      <p className="text-sm text-gray-500 mt-2">Edit Continuous Assessment (CA) Scores</p>
      <div className="mt-3">
        {error && (
          <div className="p-4 mb-4 text-sm text-white rounded-lg bg-red-500" role="alert">
            <span className="font-medium">Error!</span> {error}
          </div>
        )}
        {success && (
          <div className="p-4 mb-4 text-sm text-white rounded-lg bg-green-500" role="alert">
            <span className="font-medium">Success!</span> CA Scores Saved
          </div>
        )}
        <form className="space-y-4" onSubmit={submitHandler}>
          {subject.cas.map((ca, index) => (
            <div key={ca._id} className="flex flex-wrap gap-3 items-start justify-between mx-auto w-full p-4 border rounded-3xl">
              <div>
                <p className="text-base font-semibold">
                  CA {ca.ca} - <span className="text-gray-600">Max Score: {school?.settings?.assessment?.ca?.cas[index]?.max_score}</span>
                </p>
              </div>
              <div>
                <p className="text-sm">Enter Score</p>
                <div className="flex justify-between gap-2 items-center">
                  <input
                    type="number"
                    value={formData.caScores[index]}
                    onChange={(e) => handleInputChange(e, index)}
                    placeholder="Enter Score"
                    className="bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    max={school?.settings?.assessment?.ca?.cas[index]?.max_score}
                    required
                  />
                </div>
              </div>
            </div>
          ))}
          <div className="flex justify-between items-center">
            <button type="button" onClick={goback} className="text-gray-600 bg-white border rounded-full text-sm px-5 py-2">
              &larr; Back
            </button>
            <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-full text-sm px-5 py-2">
              {loading ? 'Saving...' : 'Save CA Scores'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditCAModal;
