import React from "react"
import { Header, NoDataComponent, SkeletonTable, StatusBadge } from "../../components"
import { useState, useEffect } from "react"
import DataTable from "react-data-table-component"
import { Setup } from "./SetupMetrics"
import { Button } from "../../components/adminDashboard/ResultsComponents"
import CustomPagination from "../../components/CustomPagination/CustomPagination"
import { MdSearch } from "react-icons/md"
import { useStateContext } from "../../contexts/ContextProvider"
import { userRequest } from "../../utils/requestMethods"
import { Link } from "react-router-dom"

const terms = [
  {
    term: 1,
    start: new Date(),
    end: new Date()
  }, 
  {
    term: 2,
    start: new Date(),
    end: new Date()
  },
  {
    term: 3,
    start: new Date(),
    end: new Date()
  }
]

const filters = [
  {
    name: "All",
    value: "All Classes"
  },
  {
    name:"1st Term",
    value: "1st Term"
  },
  {
    name:"2nd Term",
    value: "2nd Term"
  },
  {
    name:"3rd Term",
    value: "3rd Term"
  },
]

const columns = [
  {
    field: "name",
    name: "Name",
    selector: row => <span>{row.name}</span>
  },
  {
    field: "class",
    name: "Class",
    selector: row => <span>{row.class}</span>
  },
  {
    field: "ca",
    name: "CA Score (40)",
    selector: row => <span>{row.ca}</span>
  },
  {
    field: "exam",
    name: "Exam Score (60)",
    selector: row => <span>{row.exam}</span>
  },
  {
    field: "total",
    name: "Total",
    selector: row => <span>{row.ca + row.exam}</span>
  },
  {
    field: "percentage",
    name: "Percentage",
    selector: row => <span>{row.ca + row.exam}</span>
  },
  {
    field: "status",
    name: "Status",
    selector: row => <span className={`flex gap-2 items-center ${row.passed ? "text-green-500" : "text-red-500"}`}> <div className={`w-[10px] aspect-square rounded-full ${row.passed ? 'bg-green-500' : 'bg-red-500'}`}></div> {row.passed ? "Passed" : "Failed"}</span>
  },
  {
    cell: row => <button><img alt="more" src="icon-park_more.svg"/></button>,
    ignoreRowClick: true,
    button: true,
  }
]

const customStyles = {
  headRow: {
    style: {
      backgroundColor: 'rgb(248, 250, 252)',
      fontWeight: "bold",
      color: "#5C5C5C",
    },
  },
}

const ResultManagement = () =>{
  //const [filter, setFilter] = useState("All Classes");
  const [viewSetup, setViewSetup] = useState(false);

  const { school } = useStateContext();
  console.log(school?.settings);
  //const schoolTerm = school?.settings?.current_term;
  //const schoolSession = school?.settings?.current_session;
  //const currentTermData = school?.settings?.terms.find(term => term.term === schoolTerm);
  //const startDate = currentTermData?.start_date;
  //const endDate = currentTermData?.end_date;

  //const [studentAttendance, setStudentAttendance] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const [dropdown, setDropdown] = useState(false);
  const [selectedClass, setSelectedClass] = useState(() => {
    const savedClass = JSON.parse(localStorage.getItem('selectedClass'));
    return savedClass || { id: null, name: 'All classes' };
  });
  const handleDropdown = () => setDropdown(!dropdown);

  useEffect(() => {
    const fetchClasses = async () => {
      if (school) {
        try {
          setLoading(true);
          const { data } = await userRequest.get(`classes?code=${school?.code}`);
          setClasses(data.classes || []);
        } catch (error) {
          console.error("Error fetching classes:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchClasses();
  }, [school]);

  const fetchAttendance = async () => {
    setLoading(true);
    try {
      const { data } = await userRequest.get(`children/class/${selectedClass.id || ''}?limit=200`);
      console.log(data.children);
      setStudents(data.children);
    } catch (error) {
      console.error("Error fetching attendance:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedClass.id) fetchAttendance();
  }, [school?._id, selectedClass.id]);

  const handleClassSelection = (id, name) => {
    const newSelection = { id, name };
    setSelectedClass(newSelection);
    localStorage.setItem('selectedClass', JSON.stringify(newSelection));
    setDropdown(false);
  };

  const columns = [
    {
      name: "Student Name",
      selector: row => row.firstname
    },
    {
      name: "Last Name",
      selector: row => row.lastname
    },
    {
      name: "Gender",
      selector: row => row.gender
    },
    {
      name: "Class",
      selector: row => row.class_info.class_name
    },
    {
      name: "Status",
      selector: row => (
        <div>
          {row.account ? (
            <StatusBadge status="active" />
          ) : (
            <StatusBadge status="inactive" />
          )}
        </div>
      )
    },
    {
      name: 'Action',
      cell: (row) => (
        <Link to={{ pathname: `/students/view/${row._id}/${selectedClass.id}`, state: { student: row.firstname+" " +row.lastname } }} className="font-medium text-[#00509D] hover:font-semibold">View Result</Link>
      ),
      ignoreRowClick: true,
      button: true,
    },
  ];

  const filteredData = students?.filter((item) =>
    (item.firstname?.toLowerCase().includes(filterText.toLowerCase()) || '') ||
    (item.lastname?.toLowerCase().includes(filterText.toLowerCase()) || '') 
  );

  return(
    <div>
      <div className="m-2 mt-20 md:mx-10 md:my-0 py-2 md:py-7 flex flex-wrap gap-4 justify-between items-start">
        <Header category="Set Up" title="Result Management" />
        <Button onClick={() => setViewSetup(true)}>
          <img alt="icon" src="/settings-04.svg"/>
          Edit Result Metrics
        </Button>
      </div>

      <div className="my-2 mx-2 md:mx-10 flex flex-wrap justify-around gap-7">
        {school?.settings?.terms.map(term => 
          <TermResult term={term.term} start={term.start_date} end={term.end_date} />
        )}
      </div>

      <div className="m-2 md:mx-10 md:my-8 p-2 md:p-10 bg-white rounded-3xl dark:bg-secondary-dark-bg min-h-screen">
        <div className="flex justify-between items-center gap-2">
          <h1 className="text-2xl font-extrabold tracking-tight text-slate-900 dark:text-gray-200">
            Result History
          </h1>
        </div>

        <div className='w-full'>
          <div className='flex flex-wrap items-center justify-between mb-6'>
            <div className='relative w-max text-[#4543B5] text-xs'>
              <button type='button' onClick={handleDropdown} className='w-full text-sm font-bold flex items-center justify-between bg-transparent hover:bg-blue-50 border-1 border-[#C5C5E8] py-2 px-3 rounded-full'>
                <p className='mr-2'>Filter: {selectedClass.name}</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="inline ml-2 icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
              </button>
              {dropdown && (
                <div className="w-full absolute bg-white border rounded-xl shadow-md mt-2 z-10">
                  {classes.map(classe => (
                    <div
                      key={classe._id}
                      onClick={() => handleClassSelection(classe._id, classe.class_name)}
                      className="cursor-pointer text-md px-4 py-2 hover:bg-gray-100 hover:font-semibold"
                    >
                      {classe.class_name}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className='flex flex-wrap mt-4 md:mt-0 w-full md:w-max items-center justify-between md:justify-end'>
              <form className="max-w-lg">
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center pl-4 pointer-events-none">
                    <MdSearch />
                  </div>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full pl-8 p-2.5"
                    placeholder="Search"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        
        <div className="border rounded-3xl mt-12">
          <DataTable
            columns={columns}
            data={filteredData}
            noDataComponent = {<NoDataComponent/>}
            pagination
            paginationComponent={CustomPagination}
            paginationRowsPerPageOptions={[10, 20, 30, 50]}
            customStyles={customStyles}
            progressPending={loading}
            progressComponent={<SkeletonTable rows={5} columns={6} />}
            highlightOnHover
            striped
          />
        </div>

        {viewSetup && <Setup onClose={() => setViewSetup(false)}/>}
      </div>
    </div>
  )
};

const TermResult = ({term, start, end, onClick}) => {
  return(
    <div className="p-6 flex-1 bg-white min-w-[300px] rounded-3xl">
      <img alt="icon" src="/checklist.svg" className="block mb-8"/>
      <h2 className="my-3 text-2xl font-semibold">
        {term === 1 ? "1st" : term === 2 ? "2nd" : "3rd"} Term
      </h2>

      <div className="flex justify-between items-center mb-4">
        <p className="text-[#656565] text-sm"><b>Start:</b> {(new Date(start)).toLocaleDateString('en-NG')}</p>
        <p className="text-[#656565] text-sm"><b>End:</b> {(new Date(end)).toLocaleDateString('en-NG')}</p>
      </div>

      <Button variant={2} full>View Term Result</Button>
    </div>
  )
};

export default ResultManagement