import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { userRequest } from '../utils/requestMethods';
import { FiMoreHorizontal } from 'react-icons/fi';
import { Header, CustomPagination, BackButton, SkeletonTable, AddSubjectsDetailsModal, EditSubject, TableDropdown, ConfirmationModal } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import * as Icons from '../data/class';
import { MdSearch } from 'react-icons/md';

const customStyles = {
  headRow: {
    style: {
      backgroundColor: 'rgb(248, 250, 252)',
      fontWeight: "bold",
    },
  },
};

const Subjects = ({ history }) => {
  const location = useLocation();
  const classId = location.pathname.split('/')[2];
  //console.log(classId)
  const { school } = useStateContext();
  const schoolId = school?._id;
  const schoolTerm = school?.settings?.current_term;
  //const schoolSession = school?.settings?.current_session;
  const [subjects, setSubjects] = useState([]);
  const [classInfo, setClassInfo] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentsubject, setCurrentSubject] = useState([]);
  //const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState(null);
  const [filterText, setFilterText] = useState('');

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const openDeleteModal = (subject) => {
    setCurrentSubject(subject);
    setIsDeleteModalOpen(true);
  };

  useEffect(() => {
    fetchSubjects();
    fetchClass();
  }, [classId, schoolId]);

  const fetchSubjects = async () => {
    try {
      setLoading(true);
      const { data } = await userRequest.get(`subjects/${schoolId}/${classId}?limit=200`);
      //console.log(data);
      setSubjects(data.subjects);
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const fetchClass = async () => {
    try {
      //setLoading(true);
      const { data } = await userRequest.get(`classes/${schoolId}/${classId}`);
      //console.log(data);
      setClassInfo(data);
      //setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async () => {
    try {
      setDeleting(true);
      await userRequest.delete(`/subjects/${schoolId}/${classId}/${currentsubject._id}`);
      setSubjects(subjects.filter(subject => subject._id !== currentsubject._id));
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      setDeleting(false);
    }
  };

  const handleDropdownClick = (rowIndex, event) => {
    const rect = event.target.getBoundingClientRect();
    setDropdownIndex(dropdownIndex === rowIndex ? null : rowIndex);
    setDropdownPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
  };

  const handleCloseDropdown = () => setDropdownIndex(null);
  
  const columns = [
    {
      name: "Subject Name",
      selector: row => row.subject_name
    },
    {
      name: "Description",
      selector: row => row.subject_description,
      width: "25%"
    },
    {
      name: "Subject Code",
      selector: row => row.subject_code
    },
    {
      name: "Lesson Notes",
      selector: row => {
        const termNotes = row.lesson_note.find(ln => ln.term === schoolTerm);
        return termNotes ? termNotes.notes.length : 0;
      }
    },
    {
      name: "Date Created",
      selector: row => (new Date(row.created_at)).toLocaleDateString('en-NG')
    },
    {
      cell: (row, rowIndex) => (
        <div className="relative">
          <button
            className="p-2 text-gray-600 hover:text-gray-900"
            onClick={(e) => handleDropdownClick(rowIndex, e)}
          >
            <FiMoreHorizontal className="text-2xl" />
          </button>
          <TableDropdown
            isVisible={dropdownIndex === rowIndex}
            position={dropdownPosition}
            options={[
              {
                label: "View Lesson Notes",
                icon: <img src={Icons.checkList} alt="Payout Icon" />,
                onClick: () => {
                  history.push(`/notes/${row._id}/${classId}`, { subject: row });
                },
              },
              {
                label: "Edit Subject",
                icon: <img src={Icons.pencilEdit} alt="Payout Icon" />,
                onClick: () => {
                  toggleEditModal();
                  setCurrentSubject(row);
                  handleCloseDropdown();
                },
              },
              {
                label: "Delete",
                icon: <img src={Icons.delete} alt="Payout Icon" />,
                onClick: () => {
                  openDeleteModal(row);
                  handleCloseDropdown();
                },
              },
            ]}
            onClose={handleCloseDropdown}
          />
        </div>
      ),
      //button: true,
      //width: "120px",
    },
  ];

  const filteredData = subjects?.filter((item) =>
    (item.subject_name?.toLowerCase().includes(filterText.toLowerCase()) || '') ||
    (item.subject_description?.toLowerCase().includes(filterText.toLowerCase()) || '') 
  );

  return (
    <>
    <div className="px-10 pt-6">
      <BackButton history={history} />
    </div>
    <div className="px-10 pt-5">
      <div className="flex flex-wrap justify-between items-center mb-4">
      <Header category="Subjects" title={classInfo?.class_name ? `${classInfo.class_name} Subjects` : "Loading..."} />

        <div className="flex flex-wrap justify-center md:justify-end w-full md:w-auto">
          <button type="button" onClick={toggleModal} className="inline-flex justify-center py-2 px-4 mb-2 md:mb-0 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-[#3F3DA5] hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2">
            Add Subjects
          </button>
        </div>
      </div>
    </div>

    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl dark:bg-secondary-dark-bg">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-lg font-semibold w-44">Subject Details</h1>
        <div className="flex justify-center md:justify-end w-full">
          <form className="max-w-lg">
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center pl-4 pointer-events-none">
                <MdSearch />
              </div>
              <input
                type="text"
                id="email-address-icon"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                placeholder="Search"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
              />
            </div>
          </form>
          {/*<button type="button" className="inline-flex justify-center py-2 px-6 mx-2 mb-2 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2">
            Export
          </button>*/}
        </div>
      </div>
      <div className="border rounded-3xl">
        <DataTable
          data={filteredData}
          columns={columns}
          getRowId={(row) => row._id}
          pagination
          paginationComponent={CustomPagination}
          paginationRowsPerPageOptions={[10, 20, 30, 50]}
          customStyles={customStyles}
          progressPending={loading}
          progressComponent={<SkeletonTable rows={5} columns={6} />}
          highlightOnHover
          striped
        />
      </div>
      {isModalOpen && 
        <AddSubjectsDetailsModal
          closeModal={toggleModal} 
          onSubjectCreated={fetchSubjects}
          schoolId={schoolId}
          classId={classId}
        />
      }

      {isEditModalOpen && 
        <EditSubject
          closeModal={toggleEditModal} 
          onSubjectUpdated={fetchSubjects}
          currentsubject={currentsubject}
          schoolId={schoolId}
          classId={classId}
        />
      }

      <ConfirmationModal
        isVisible={isDeleteModalOpen}
        title="Delete Subject"
        description={`Are you sure you want to delete the subject "${currentsubject?.subject_name}"? This action cannot be undone.`}
        onConfirm={handleDelete}
        onCancel={() => setIsDeleteModalOpen(false)}
        deleting={deleting}
      />
    </div>
    </>
  );
};
export default Subjects;
