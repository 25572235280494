import { MdAdd } from "react-icons/md"
import { Button } from "./ResultsComponents"
export default function ContinousAssessment({ca, setCa, error, setError}){
  const updateCA = (name, value) => {
    setCa(ca.map(item => item.name === name ? 
      {...item, value} : item))
  }
  const handleCAChange = (e) => {
    const name = parseInt(e.target.name)
    const value = parseInt(e.target.value ?? "0")
    let sum = 0
    updateCA(name, value)
    sum += name !== 0 ? value : 0
    for (let i = 1; i < ca.length; i = i + 1){
      if (i === name){
        continue
      }
      sum += ca[i].value
    }
    if (ca[0].value !== sum){
      setError(true)
    } else{
      setError(false)
    }
  }

  return(
    <div>
      {
        ca.map(currentCa => 
          <div className={`${currentCa.name === 0 && "bg-[#EEEEFC]"} my-3 p-4 rounded-3xl flex justify-between items-start gap-3 border`}>
            <p className="text-base font-semibold flex-1">{currentCa.name === 0 ? "Total Weight" : "CA " + currentCa.name}</p>

            <div className="flex-1">
              <label className="text-sm">{currentCa.name === 0 ? "Total CA Score" : "Maximum Score"} </label>
              <input name={currentCa.name} value={currentCa.value} onChange={handleCAChange} type="number" min={0} className="block my-1 w-full rounded-md text-black font-semibold"/>
              {currentCa.name !== 0 && error && <p id={`error${currentCa.name}`} className="text-red-500">Sum of CA's must be equal to total weight</p>}
            </div>

          </div>
        )
      }
      {ca.length === 3 && 
      <Button 
      onClick={() => setCa(prev => [...prev, {name: 3, value: 0}])} 
      variant={2} 
      full
      ><MdAdd/> Add CA</Button>}
    </div>
  )
}