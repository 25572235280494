import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { userRequest } from '../utils/requestMethods';
import { Header, AddDiscountModal, CustomPagination, SkeletonTable } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import useKYCStatusRedirect from '../hooks/useKYCStatusRedirect';
import { displaySchoolTerm } from '../utils/schoolYearUtils';

const customStyles = {
  headRow: {
    style: {
      backgroundColor: 'rgb(248, 250, 252)',
      fontWeight: "bold",
    },
  },
};

const Discounts = () => {
  useKYCStatusRedirect("/discounts");
  const { school } = useStateContext();
  const [discount, setDiscount] = useState();
  const [parents, setParents] = useState();
  const [percentage, setPercentage] = useState("");
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const schoolId = school?._id;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  //console.log(Discount);
  /*console.log(schoolId);*/

  useEffect(() => {
    const fetchDiscount = async () => {
      if (schoolId) {
        try {
          setLoading(true);
          const { data } = await userRequest.get(`discount/${schoolId}?sort_by=date&order=des`);
          console.log(data);
          setDiscount(data.discounts);
          setLoading(false);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchDiscount();
  }, [schoolId, refresh]);

  useEffect(() => {
    const fetchDiscount = async () => {
      if (schoolId) {
        try {
          setLoading(true);
          const { data } = await userRequest.get(`schools/${schoolId}/parents`);
          console.log(data);
          setParents(data);
          setLoading(false);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchDiscount();
  }, [schoolId]);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!percentage || !user) {
      setError("Please Fill all the Feilds");
      setTimeout(() => {
          setError("");
      }, 2000);
      setLoading(false);
      return;
    }
    try {
      await userRequest.post(`/discount/create/${schoolId}`,
        {
          percentage: percentage,
          user: user,
          term: school.term,
          session: school.session,
        });
      //console.log(data);
      //history.push("/classes");
      setRefresh(!refresh);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setPercentage("");
        setUser("");
        toggleModal();
      }, 2000);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
    }
    setLoading(false);
  };

  /*const deleteHandler = async (addonId) => {
    //console.log(classId);
    try {
      await userRequest.delete(`/transport-addon/${schoolId}/${addonId}`);
      //console.log(res.data.add_on);
      //setDiscount(res.data.add_on)
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };*/

  const columns = [
    {
      name: "Discount Percentage",
      selector: row => <span className="font-bold">{row.percentage}%</span>
    },
    {
      name: "Parent",
      selector: row => row?.user?.firstname + " " + row?.user?.lastname
    },
    {
      name: "Term",
      selector: row => <span className="font-bold">{displaySchoolTerm(row?.term)} - {row.session}</span>
    },
    {
      name: "Date",
      selector: row => (new Date(row.createdAt)).toLocaleDateString('en-NG'),
      sortable: true
    },
    {
      cell: (row) => <button style={{ background: 'rgb(139, 231, 139)' }} className="text-white py-1 px-2 capitalize rounded-2xl text-md font-bold">Active</button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) => <Link to={`/discounts`} style={{ color: '#1e4db7', backgroundColor: '#91a6f5' }} className="text-md opacity-0.9 rounded-full p-3 hover:drop-shadow-xl"><FiEdit /></Link>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) => <button style={{ color: 'rgb(228, 106, 118)', backgroundColor: 'rgb(255, 244, 229)' }} className="text-md opacity-0.9 rounded-full p-3 hover:drop-shadow-xl"><FiTrash2 /></button>,
      ignoreRowClick: true,
      button: true,
    },
  ];

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl min-h-screen">
        <button type="button" onClick={toggleModal} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-700 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right">
          Give Discount
        </button>
      <Header category="Set up" title="Manage Discounts" />
      <div className="border rounded-3xl">
        <DataTable
          data={discount}
          columns={columns}
          getRowId={(row) => row._id}
          pagination
          paginationComponentOptions={{
            rowsPerPageText: 'Rows per page:',
            rangeSeparatorText: 'of',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'All',
          }}
          paginationComponent={CustomPagination}
          paginationRowsPerPageOptions={[10, 20, 30, 50]}
          customStyles={customStyles}
          progressPending={loading}
          progressComponent={<SkeletonTable rows={5} columns={6} />}
        />
      </div>

      {isModalOpen && 
        <AddDiscountModal 
        closeModal={toggleModal}
        parents={parents}
        percentage={percentage}
        setPercentage={setPercentage}
        user={user}
        setUser={setUser}
        error={error}
        success={success}
        loading={loading} 
        submitHandler={submitHandler}
        />
      }
    </div>
  );
};
export default Discounts;
