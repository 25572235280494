import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import { userRequest } from "../../../utils/requestMethods";
import { Header, CustomPagination, SkeletonTable, AlertNotification, BackButton } from "../../../components";
import { useStateContext } from "../../../contexts/ContextProvider";
import { MdSearch } from "react-icons/md";
import * as Icons from "../../../data/teacher";

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "rgb(248, 250, 252)",
      fontWeight: "bold",
    },
  },
};

const StudentAttendance = ({ history }) => {
  const { school } = useStateContext();
  const location = useLocation();
  const classId = location.pathname.split('/')[4];
  //console.log(classId);
  const schoolId = school?._id;
  const [filterText, setFilterText] = useState('');
  const [students, setStudents] = useState([]);
  const [attendance, setAttendance] = useState({});
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]); // Current date in YYYY-MM-DD format
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  //console.log(attendance);

  const fetchStudents = async (classId) => {
    if (classId) {
      try {
        setLoading(true);
        const { data } = await userRequest.get(`children/class/${classId}?limit=200`);
        setStudents(data.children);
        initializeAttendance(data.children);
      } catch (error) {
        console.error("Error fetching students:", error);
        setError("Error fetching students:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (classId) fetchStudents(classId);
  }, [classId]);

  const initializeAttendance = (students) => {
    setAttendance((prevAttendance) => {
      const updatedAttendance = { ...prevAttendance };
      students.forEach((student) => {
        if (!(student._id in updatedAttendance)) {
          updatedAttendance[student._id] = null; // Initialize only if not already set
        }
      });
      return updatedAttendance;
    });
  };

  const handleAttendanceChange = (childId, status) => {
    setAttendance((prev) => ({ ...prev, [childId]: status }));
  };

  const saveAttendance = async () => {
    setSaving(true);
    const attendanceData = Object.entries(attendance).map(([childId, status]) => ({
      childId,
      status: status === null ? 0 : status, // Pending is represented as 0
    }));

    const payload = {
      attendance: attendanceData,
      classId: classId,
      schoolId,
      date: date, 
      reason: "Daily attendance",
    };

    try {
      await userRequest.post("/attendance/mark-multiple-student-attendance", payload);
      setSuccess("Attendance saved successfully!");
      setTimeout(() => setSuccess(""), 5000);
    } catch (error) {
      console.error("Error saving attendance:", error);
      setError("Error saving attendance:", error);
      setTimeout(() => setError(""), 5000);
    } finally {
      setSaving(false);
    }
  };

  const columns = [
    {
      name: "Student Name",
      cell: (row) => `${row.firstname} ${row.lastname}`,
    },
    {
      name: "Present",
      cell: (row) => (
        <div className="flex items-center space-x-4">
          <input
            type="radio"
            name={`attendance-${row._id}`}
            id={`present-${row._id}`}
            className="hidden peer"
            checked={attendance[row._id] === 1} // Sync with state
            onChange={() => handleAttendanceChange(row._id, 1)}
          />
          <label htmlFor={`present-${row._id}`} className="cursor-pointer p-1 bg-gray-300 rounded-full">
            <img
              src={
                attendance[row._id] === 1
                  ? Icons.studentPresent
                  : Icons.studentPending
              }
              alt="Present Icon"
            />
          </label>
        </div>
      ),
    },
    {
      name: "Absent",
      cell: (row) => (
        <div className="flex items-center space-x-4">
          <input
            type="radio"
            name={`attendance-${row._id}`}
            id={`absent-${row._id}`}
            className="hidden peer"
            checked={attendance[row._id] === 2} // Sync with state
            onChange={() => handleAttendanceChange(row._id, 2)}
          />
          <label htmlFor={`absent-${row._id}`} className="cursor-pointer p-1 bg-gray-300 rounded-full">
            <img
              src={
                attendance[row._id] === 2
                  ? Icons.studentAbsent
                  : Icons.studentPending
              }
              alt="Absent Icon"
            />
          </label>
        </div>
      ),
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
    },
    {
      name: "Class",
      selector: (row) => row.class_info.class_name,
    },
    {
      name: "Action",
      cell: (row) => (
        <Link
          to={{ pathname: `/t/attendance/view/${row._id}`, state: { student: row } }}
          className="font-medium text-[#00509D] hover:underline"
        >
          View
        </Link>
      ),
      ignoreRowClick: true,
      button: true,
    },
  ];

  const filteredData = students?.filter((item) =>
    (item.firstname?.toLowerCase().includes(filterText.toLowerCase()) || '') ||
    (item.lastname?.toLowerCase().includes(filterText.toLowerCase()) || '')
  );

  return (
    <div className="px-3 md:px-10 pt-10 md:pt-5 mt-10">
      <BackButton history={history} />
      <div className="flex flex-wrap justify-between items-center mb-4">
        <Header category="Attendance" title="Mark Student Attendance" />
      </div>

      <div className="p-4 md:p-10 bg-white rounded-3xl dark:bg-secondary-dark-bg min-h-screen">
        <div className="flex flex-wrap justify-between items-center gap-4 mb-4">
          {/* Date Input */}
          <div className="relative w-full sm:w-auto">
            <input type="date" onChange={(e) => setDate(e.target.value)} value={date} className="w-full sm:w-auto bg-gray-50 border border-gray-300 text-gray-900 text-sm sm:text-md rounded-full focus:ring-blue-500 focus:border-blue-500 block p-2.5" placeholder="Select Date" />
          </div>
          {/* Search and Save Attendance Section */}
          <div className="flex flex-wrap justify-center md:justify-end w-full md:w-auto gap-4">
            {/* Search Input */}
            <form className="w-full md:w-auto">
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center pl-4 pointer-events-none">
                  <MdSearch />
                </div>
                <input type="text" value={filterText} onChange={(e) => setFilterText(e.target.value)} className="w-full sm:w-auto bg-gray-50 border border-gray-300 text-gray-900 text-sm sm:text-md rounded-full focus:ring-blue-500 focus:border-blue-500 block pl-8 p-2.5" placeholder="Search"/>
              </div>
            </form>
            {/* Save Attendance Button */}
            <button type="button" onClick={saveAttendance} className="w-full sm:w-auto py-2 px-6 border border-transparent shadow-sm text-sm sm:text-md font-medium rounded-full text-white bg-[#3F3DA5] hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50" disabled={saving}>
              {saving ? "Saving..." : "Save Attendance"}
            </button>
          </div>
        </div>

        <div className="border rounded-3xl">
          <DataTable
            data={filteredData}
            columns={columns}
            getRowId={(row) => row._id}
            keyField="_id"
            pagination
            paginationComponentOptions={{
              rowsPerPageText: "Rows per page:",
              rangeSeparatorText: "of",
              selectAllRowsItem: true,
              selectAllRowsItemText: "All",
            }}
            paginationComponent={CustomPagination}
            paginationRowsPerPageOptions={[10, 20, 30, 50]}
            customStyles={customStyles}
            progressPending={loading}
            progressComponent={<SkeletonTable rows={5} columns={6} />}
            highlightOnHover
            striped
          />
        </div>
      </div>
      {error && 
        <AlertNotification type="error" message={error} onClose={() => setError("")} />}
      {success && 
        <AlertNotification type="success" message={success} onClose={() => setSuccess("")} />}
    </div>
  );
};

export default StudentAttendance;
