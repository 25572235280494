import React from 'react';
import { BsCreditCard, BsPerson, BsShieldCheck } from 'react-icons/bs';
import * as Icons from './nav';

export const gridOrderImage = (props) => (
  <div>
    <img
      className="rounded-xl h-20 md:ml-3"
      src={props.ProductImage}
      alt="order-item"
    />
  </div>
);

export const gridOrderStatus = (props) => (
  <button
    type="button"
    style={{ background: props.StatusBg }}
    className="text-white py-1 px-2 capitalize rounded-2xl text-md"
  >
    {props.Status}
  </button>
);


export const links = [
  {
    title: 'Dashboard',
    links: [
      {
        name: 'Get Started',
        icon: <img src={Icons.more} alt="Nav Icon" />,
        url: 'onboarding/get-started',
      },
      {
        name: 'Home',
        icon: <img src={Icons.home} alt="Nav Icon" />,
        url: 'home',
      },
    ],
  },

  {
    title: 'Setup',
    links: [
      {
        name: 'All classes',
        icon: <img src={Icons.userMultiple} alt="Nav Icon" />,
        url: 'classes',
      },
      {
        name: 'School Bus',
        icon: <img src={Icons.bus} alt="Nav Icon" />,
        url: 'transportation',
      },
      {
        name: 'Discounts',
        icon: <img src={Icons.coupon} alt="Nav Icon" />,
        url: 'discounts',
      },
      {
        name: "Result Management",
        icon: <img src={Icons.documentAttachment} alt="Nav Icon" />,
        url: 'result-management'
      }
    ],
  },

  {
    title: 'Teacher',
    links: [
      {
        name: 'Teacher Management',
        icon: <img src={Icons.teacher} alt="Nav Icon" />,
        url: 'management/teacher',
      },
      {
        name: 'Attendance',
        icon: <img src={Icons.tickDouble} alt="Nav Icon" />,
        url: 'management/attendance',
      },
    ],
  },
  
  {
    title: 'Payments',
    links: [
      {
        name: 'view all payments',
        icon: <img src={Icons.creditCardPos} alt="Nav Icon" />,
        url: 'all-payments',
      },
      {
        name: 'payments per class',
        icon: <img src={Icons.payment} alt="Nav Icon" />,
        url: 'class-payments',
      },
      {
        name: 'Extra Fees',
        icon: <img src={Icons.invoice} alt="Nav Icon" />,
        url: 'class-addons',
      },
    ],
  },
  {
    title: 'Flexpay',
    links: [
      {
        name: 'Flexpay',
        icon: <img src={Icons.invoice} alt="Nav Icon" />,
        url: 'flexpay',
      },
      /*{
        name: 'Flexpay Settlement',
        icon: <FaChartBar />,
        url: 'flexpay-history',
      },*/
    ],
  },
  /*{
    title: 'KYC',
    links: [
      {
        name: 'Personal',
        icon: <BsFillPersonPlusFill />,
        url: 'compliance/personal',
      },
      {
        name: 'Business',
        icon: <BsFillJournalBookmarkFill />,
        url: 'compliance/business',
      },
      {
        name: 'Payment',
        icon: <FiCreditCard />,
        url: 'compliance/payment',
      },
    ],
  },*/
  {
    title: 'Financing',
    links: [
      {
        name: 'Cashflow',
        icon: <img src={Icons.cash} alt="Nav Icon" />,
        url: 'financing/cashflow',
      },
    ],
  },
  {
    title: 'Payouts',
    links: [
      {
        name: 'Withdraw',
        icon: <img src={Icons.moneyWithdraw} alt="Nav Icon" />,
        url: 'withdraw',
      },
      {
        name: 'Transfer',
        icon: <img src={Icons.transferHorizontal} alt="Nav Icon" />,
        url: 'expenses',
      },
    ],
  },
  {
    title: 'Help',
    links: [
      {
        name: 'Support',
        icon: <img src={Icons.messageQuestion} alt="Nav Icon" />,
        url: 'support',
      },
    ],
  },
];

export const linksTeacher = [
  {
    title: 'Teacher',
    links: [
      {
        name: 'Dashboard',
        icon: <img src={Icons.home} alt="Nav Icon" />,
        url: 'teacher',
      },
      {
        name: 'Student Attendance',
        icon: <img src={Icons.tickDouble} alt="Nav Icon" /> ,
        url: 't/attendance',
      },
      {
        name: 'Lesson Notes',
        icon: <img src={Icons.documentAttachment} alt="Nav Icon" />,
        url: 't/subjects',
      },
      {
        name: 'Results',
        icon: <img src={Icons.invoice} alt="Nav Icon" />,
        url: 't/results',
      },
    ],
  },
];

export const themeColors = [
  {
    name: 'blue-theme',
    color: '#1A97F5',
  },
  {
    name: 'green-theme',
    color: '#03C9D7',
  },
  {
    name: 'purple-theme',
    color: '#7352FF',
  },
  {
    name: 'red-theme',
    color: '#FF5C8E',
  },
  {
    name: 'indigo-theme',
    color: '#1E4DB7',
  },
  {
    color: '#FB9678',
    name: 'orange-theme',
  },
];

export const userProfileData = [
  {
    icon: <BsPerson />,
    title: 'My Profile',
    desc: 'Account Settings',
    iconColor: '#4543B5',
    iconBg: 'rgb(238, 235, 250)',
    link: '/profile',
  },
  {
    icon: <BsShieldCheck />,
    title: 'Compliance',
    desc: 'KYC & payouts account',
    iconColor: '#4543B5',
    iconBg: 'rgb(238, 235, 250)',
    link: '/onboarding/get-started',
  },
  {
    icon: <BsCreditCard />,
    title: 'Withdrawal',
    desc: 'Fund Withdrawal & Info',
    iconColor: '#4543B5',
    iconBg: 'rgb(238, 235, 250)',
    link: '/withdraw',
  },
];

export const teacherProfileData = [
  {
    icon: <BsPerson />,
    title: 'My Profile',
    desc: 'Account Settings',
    iconColor: '#4543B5',
    iconBg: 'rgb(238, 235, 250)',
    link: '/profile',
  },
];