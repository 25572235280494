import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { userRequest } from "../../utils/requestMethods";

const AssignSubjectsModal = ({ onClose, teacher, school }) => {
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [classId, setClassId] = useState("");
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [loadingClasses, setLoadingClasses] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    let isMounted = true;

    const fetchClasses = async () => {
      if (!school?.code) return;

      try {
        setLoadingClasses(true);
        const { data } = await userRequest.get(`classes?code=${school?.code}`);
        if (isMounted) setClasses(data.classes || []);
      } catch (error) {
        if (isMounted) console.error("Error fetching classes:", error);
      } finally {
        if (isMounted) setLoadingClasses(false);
      }
    };

    fetchClasses();

    return () => {
      isMounted = false;
    };
  }, [school?.code]);

  const fetchSubjects = async (classId) => {
    if (!classId) return;
    setLoadingClasses(true);
    try {
      const { data } = await userRequest.get(`/subjects/${school?._id}/${classId}?limit=100`);
      setSubjects(data.subjects || []);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    } finally {
      setLoadingClasses(false);
    }
  };

  useEffect(() => {
    fetchSubjects(classId);
  }, [classId, school?._id]);

  // Add selected subject to the list
  const handleSelect = (e) => {
    const subjectId = e.target.value;
    const subject = subjects.find((subj) => subj._id === subjectId);

    if (subject && !selectedSubjects.includes(subjectId)) {
      setSelectedSubjects([...selectedSubjects, subjectId]);
    }
    setSelectedOption(""); // Reset the dropdown
  };

  // Remove a subject from the list
  const handleRemove = (subjectIdToRemove) => {
    const updatedSubjects = selectedSubjects.filter((id) => id !== subjectIdToRemove);
    setSelectedSubjects(updatedSubjects);
  };

  const handleSubmit = () => {
    console.log("Assigned Subjects (IDs):", selectedSubjects);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (!classId) {
        setError("Please select a class.");
        return;
    }

    try {
        setLoading(true);
        await userRequest.post(`/teachers/assign-subjects`, {
            schoolId: school._id,
            teacherId: teacher._id,
            classId,
            subjectIds: selectedSubjects,
        });
        setSuccess("Subjects assigned successfully!");
        onClose(); // Close the modal after a successful submission
    } catch (error) {
        console.error("Error assigning class:", error);
        setError(error.response?.data || "An unexpected error occurred.");
    } finally {
        setLoading(false);
    }
};

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
      <div className="bg-white rounded-2xl w-full md:w-1/3 w-2/3 rounded-3xl p-6 shadow-lg">
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Assign Subject</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <FaTimes />
          </button>
        </div>
        <div className="mb-4">
          <label className="block text-sm text-gray-600 mb-2">Select Class</label>
          <select
            onChange={(e) => setClassId(e.target.value)}
            value={classId}
            className="w-full border border-gray-300 rounded-lg p-2 text-sm focus:ring-2 focus:ring-indigo-500 focus:outline-none"
            disabled={loading}
          >
            <option value="" disabled>
              {loadingClasses ? "Loading classes..." : "Select a Class"}
            </option>
            {classes.length > 0 ? (
              classes.map((classe) => (
                <option key={classe._id} value={classe._id}>
                  {classe.class_name}
                </option>
              ))
            ) : (
              <option disabled>No classes available</option>
            )}
          </select>
        </div>

        {/* Dropdown */}
        <div className="mb-4">
          <label className="block text-sm text-gray-600 mb-2">Select subject</label>
          <select
            value={selectedOption}
            onChange={handleSelect}
            className="w-full border border-gray-300 rounded-lg p-2 text-sm focus:ring-2 focus:ring-indigo-500 focus:outline-none"
          >
            <option value="" disabled>
              {loading ? "Loading subjects..." : "Select a subject"}
            </option>
            {subjects.length > 0 ? (
              subjects.map((subject) => (
                <option key={subject._id} value={subject._id}>
                  {subject.subject_name}
                </option>
              ))
            ) : (
              <option disabled>No subjects available</option>
            )}
          </select>
        </div>

        {/* Selected Subjects */}
        <div className="flex flex-wrap gap-2 mb-6">
          {selectedSubjects.map((subjectId) => {
            const subject = subjects.find((subj) => subj._id === subjectId);
            return (
              <div
                key={subjectId}
                className="flex items-center bg-indigo-50 text-indigo-500 rounded-lg px-3 py-1 text-sm space-x-1"
              >
                <span>{subject?.subject_name || "Unknown"}</span>
                <button
                  onClick={() => handleRemove(subjectId)}
                  className="text-red-500 hover:text-red-600"
                >
                  <MdClose />
                </button>
              </div>
            );
          })}
        </div>

        {/* Submit Button */}
        <div className="text-right">
            <button onClick={submitHandler} className="px-5 py-3 bg-[#3F3DA5] text-white text-sm font-semibold rounded-full hover:bg-indigo-800 transition" disabled={loading}>
                {loading ? "Assigning..." : "Assign Now"}
            </button>
        </div>
      </div>
    </div>
  );
};

export default AssignSubjectsModal;
