import React, { useState } from 'react';
import { userRequest } from '../../../utils/requestMethods';

const EditExamModal = ({
  closeModal,
  school,
  schoolId,
  teacherId,
  childId,
  subjectId,
  classId,
  goback,
  onResultEdited,
  subject,
}) => {
  const [score, setScore] = useState(subject?.exam || ""); // Load the current exam score
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const validateScore = () => {
    const maxScore = school?.settings?.assessment?.exam?.max_score;
    return score <= maxScore && score >= 0;
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!validateScore()) {
      setError(`Score must be between 0 and ${school?.settings?.assessment?.exam?.max_score}.`);
      setTimeout(() => setError(''), 5000);
      return;
    }

    setLoading(true);

    try {
      const payload = {
        childId,
        schoolId,
        session: school?.settings?.current_session,
        term: school?.settings?.current_term,
        classId,
        subjectId,
        newScore: parseFloat(score), // Ensure score is sent as a number
      };

      await userRequest.put(`/result/exam`, payload); // Assuming PUT for updating
      setSuccess(true);
      setTimeout(() => setSuccess(false), 2000);

      if (onResultEdited) onResultEdited(); // Notify parent of update
      //if (closeModal) closeModal();
    } catch (error) {
      console.error(error);
      setError('An error occurred while updating the exam score.');
      setTimeout(() => setError(''), 5000);
    }

    setLoading(false);
  };

  return (
    <div className="mt-4">
      <p className="text-sm text-gray-500 mt-2">Edit Exam Score for {subject?.name}</p>
      <div className="mt-3">
        {error && (
          <div className="p-4 mb-4 text-sm text-white rounded-lg bg-red-500" role="alert">
            <span className="font-medium">Error!</span> {error}
          </div>
        )}
        {success && (
          <div className="p-4 mb-4 text-sm text-white rounded-lg bg-green-500" role="alert">
            <span className="font-medium">Success!</span> Exam Score Updated
          </div>
        )}
        <form className="space-y-4" onSubmit={submitHandler}>
          <div className="flex flex-wrap gap-3 items-start justify-between mx-auto w-full p-4 bg-[#C5C5E8] border rounded-3xl">
            <p className="text-base font-semibold">Maximum Exam Score</p>
            <div>
              <p className="text-sm">Max Score</p>
              <input
                type="number"
                value={school?.settings?.assessment?.exam?.max_score}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                disabled
              />
            </div>
          </div>
          <div className="flex flex-wrap gap-3 items-start justify-between mx-auto w-full p-4 border rounded-3xl">
            <p className="text-base font-semibold">Current Exam Score</p>
            <div>
              <p className="text-sm">Enter New Score</p>
              <div className="flex justify-between gap-2 items-center">
                <input
                  type="number"
                  onChange={(e) => setScore(e.target.value)}
                  value={score}
                  placeholder="Enter New Score"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                  max={school?.settings?.assessment?.exam?.max_score}
                  required
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <button
              type="button"
              onClick={goback}
              className="text-gray-600 bg-white border rounded-full text-sm px-5 py-2"
            >
              &larr; Back
            </button>
            <button
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-full text-sm px-5 py-2"
            >
              {loading ? 'Saving...' : 'Save Exam Score'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditExamModal;
