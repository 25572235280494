import React, { useState } from "react";
import EditCAModal from "./EditCAModal";
import EditExamModal from "./EditExamModal";

const ResultDetailsModal = ({ closeModal, onResultEdited, childId, teacherId, school, classId, subject }) => {
  const [screen, setScreen] = useState("main");
  console.log(subject);

  // Handle navigation between screens
  const navigateTo = (targetScreen) => {
    setScreen(targetScreen);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex items-center justify-center z-50">
      <div className="bg-white rounded-2xl w-full md:w-1/3 p-6">
        {/* Close Button */}
        <div className="flex justify-between items-center">
            <h2 className="text-lg font-semibold">Edit {subject?.subject.name} Result</h2>
            <button type="button" onClick={closeModal} className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span className="sr-only">Close modal</span>
            </button>
        </div>

        {/* Main Screen */}
        {screen === "main" && (
          <div className="mt-4">
            <p className="text-sm text-gray-500">
              Set up your academic terms start and end dates
            </p>

            {/* test results */}
            <button onClick={() => navigateTo("ca")} className="mt-4 w-full flex justify-between bg-gray-50 border border-gray-200 rounded-lg px-4 py-3 hover:bg-gray-100">
              <div className="text-left">
                <h3 className="text-sm font-bold">CA</h3>
                <p className="text-sm text-gray-500">
                  Upload continuous assessment test result
                </p>
              </div>
              <span className="text-gray-400 text-lg">&rarr;</span>
            </button>

            {/* exam */}
            <button onClick={() => navigateTo("exam")} className="mt-4 w-full flex justify-between bg-gray-50 border border-gray-200 rounded-lg px-4 py-3 hover:bg-gray-100">
              <div className="text-left">
                <h3 className="text-sm font-bold">Exam</h3>
                <p className="text-sm text-gray-500">
                  Upload students student exam scroe
                </p>
              </div>
              <span className="text-gray-400">&rarr;</span>
            </button>
          </div>
        )}

        {/* test results Screen */}
        {screen === "ca" && (
          <EditCAModal 
            goback={() => navigateTo("main")}
            closeModal={closeModal}
            onResultEdited={onResultEdited}
            school={school}
            schoolId={school._id}
            classId={classId}
            childId={childId}
            teacherId={teacherId}
            subjectId={subject.subject.id}
            subject={subject}
          />
        )}
        {/* Exam Screen */}
        {screen === "exam" && (
          <EditExamModal 
            goback={() => navigateTo("main")}
            closeModal={closeModal}
            onResultEdited={onResultEdited}
            school={school}
            schoolId={school._id}
            classId={classId}
            childId={childId}
            teacherId={teacherId}
            subjectId={subject.subject.id}
            subject={subject}
          />
        )}
      </div>
    </div>
  );
};

export default ResultDetailsModal;
