import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { userRequest } from '../../../utils/requestMethods';
import { Header, BackButton, SkeletonCards, AddLessonNoteModal, ConfirmationModal } from '../../../components';
import { useStateContext } from '../../../contexts/ContextProvider';
import * as Icons from '../../../data/class';
import { FiDownloadCloud } from 'react-icons/fi';

const TeacherLessonNotes = ({ history }) => {
  const location = useLocation();
  const subjectId = location.pathname.split('/')[4];
  const classId = location.pathname.split('/')[5];
  const subject = location.state?.subject;
  console.log(subject)
  const { user, school } = useStateContext();
  const schoolId = school?._id;
  const schoolTerm = school?.settings?.current_term;
  const schoolSession = school?.settings?.current_session;
  const [notes, setNotes] = useState([]);
  const [currentNote, setCurrentNote] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  //const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    fetchSubjects();
  }, [classId, schoolId]);

  const fetchSubjects = async () => {
    try {
      setLoading(true);
      const { data } = await userRequest.get(`teachers/lesson-notes/${schoolId}/${classId}/${subjectId}?term=${schoolTerm}&session=${schoolSession}`);
      console.log(data);
      setNotes(data.lesson_notes);
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  /*const handleDownload = (attachmentUrl, title) => {
    const link = document.createElement('a');
    link.href = attachmentUrl;
    link.download = title;
    link.click();
  };*/

  const handleDownload = (attachmentUrl, title) => {
    const link = document.createElement('a');
    link.href = attachmentUrl;
    link.download = title.includes('.') ? title : `${title}.pdf`; // default extension if not present
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const handleDelete = async () => {
    try {
      setDeleting(true);
      await userRequest.delete(`/teachers/lesson-notes/${schoolId}/${classId}/${subjectId}/${user._id}/${currentNote._id}`);
      setNotes(notes.filter(note => note._id !== currentNote._id));
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      setDeleting(false);
    }
  };

  return (
    <>
    <div className="px-3 md:px-10 pt-10 md:pt-5 mt-10">
      <BackButton history={history} />
    </div>
    <div className="px-4 md:px-10 pt-5">
      <div className="flex flex-wrap justify-between items-center mb-4">
        <Header category="Lesson Notes" title={subject?.subject_name || "Loading..."} />
        <button type="button" onClick={toggleModal} className="inline-flex justify-center py-2 px-4 mb-2 md:mb-0 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-[#3F3DA5] hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2">
          Add Lesson Note
        </button>
      </div>
    </div>

    <div className="m-2 md:m-10 mt-10 p-4 md:p-10 bg-white rounded-3xl dark:bg-secondary-dark-bg">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        {loading ? (
          <SkeletonCards cardCount={4} layout="vertical" width="" />
        ) : notes.length > 0 ? (
          notes.map((note, index) => (
          <div key={index} className='pr-3'>
            <div className='border border-[#C5C5E8] rounded-3xl'>
              <div className='bg-[#EEEEFC] flex items-center rounded-tr-3xl rounded-tl-3xl  justify-center px-4 py-14'>
                <img src={Icons.fileType} alt="Nav Icon" />
              </div>
              <div className='flex flex-wrap items-center justify-between p-3'>
                <div>
                  <p className='text-sm font-semibold mt-3'>{note.title}</p>
                  <p className='text-xs mt-2 mb-3'>Uploaded {(new Date(note.updated_at)).toLocaleDateString('en-NG')}</p>
                </div>
                <div className="flex items-center">
                  <button
                    onClick={() => handleDownload(note.attachment, note.title)}
                    className="mt-4 p-2 text-blue-500 rounded-full bg-blue-100 hover:text-blue-700 hover:bg-blue-200"
                  >
                    <FiDownloadCloud className="text-2xl" />
                  </button>
                  <button onClick={() => { setCurrentNote(note); setIsDeleteModalOpen(true);}} className="ml-4 mt-4 p-2 text-red-500 rounded-full bg-red-100 hover:text-red-700 hover:bg-red-200">
                    <img src={Icons.delete} alt="Delete Icon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          ))
        ) : (
          <div className="col-span-4 flex items-center justify-center w-full h-full text-gray-500">
            <p>No lesson note uploaded for the subject yet.</p>
          </div>
        )}
      </div>
      {isModalOpen && 
        <AddLessonNoteModal
          closeModal={toggleModal} 
          onNoteUploaded={fetchSubjects}
          teacherId={user._id}
          schoolId={schoolId}
          subjectId={subjectId}
          classId={classId}
        />
      }

      <ConfirmationModal
        isVisible={isDeleteModalOpen}
        title="Delete Lesson Note"
        description={`Are you sure you want to delete the subject "${currentNote?.title}"? This action cannot be undone.`}
        onConfirm={handleDelete}
        onCancel={() => setIsDeleteModalOpen(false)}
        deleting={deleting}
      />
    </div>
    </>
  );
};
export default TeacherLessonNotes;
