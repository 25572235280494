import React from 'react';

const SkeletonCards = ({ cardCount = 1, layout = "vertical", width = "md:w-1/3 w-1/2" }) => {
  const skeletonCards = Array.from({ length: cardCount });

  return (
    <>
      {skeletonCards.map((_, index) => (
        <div key={index} className={`pr-3 ${width}`}>
          <div className='border border-[#C5C5E8] p-3 rounded-2xl animate-pulse'>
            <div className='bg-[#EEEEFC] flex items-center justify-center rounded-2xl px-4 py-14'>
              <div className='w-12 h-12 bg-gray-300 rounded-full'></div>
            </div>
            <div className='mt-3'>
              <div className='h-4 bg-gray-300 rounded w-3/4 mb-2'></div>
              <div className='h-4 bg-gray-300 rounded w-1/2'></div>
            </div>
            <div className={`flex ${layout === "horizontal" ? "flex-row" : "flex-wrap"} items-center justify-between mt-3`}>
              <div className='h-8 bg-gray-300 rounded-full w-1/2'></div>
              <div className='h-8 bg-gray-300 rounded-full w-1/3'></div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default SkeletonCards;
