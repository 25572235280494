import { MdClose } from "react-icons/md"
import { useState } from "react"
const Toggle = ({toggle, setToggle}) => {
  const handleClick = () => {
    setToggle(!toggle)
  }
  return(
    <button onClick={handleClick} className={`flex items-center ml-1 p-1 w-6 h-4 rounded-lg border ${toggle ? "bg-blue-800 justify-end border-transparent" : "bg-white border-slate-400"}`}>
      <div className={`w-2 aspect-square rounded-full ${toggle ? "bg-white" : "bg-gray-600"}`}></div>
    </button>
  )
}

const Button = ({variant = 1, full, active, children, ...props}) => {
  return(
    <button {...props} className={`flex justify-center items-center gap-3 py-3 px-5 font-semibold border border-transparent rounded-3xl disabled:opacity-70 ${variant === 1 && "bg-[#3F3DA5] text-white hover:bg-[#5552db]"} ${variant === 2 && "text-[#3F3DA5] bg-white border-[#C5C5E8] hover:bg-[#EEEEFC]"} ${full && "w-full"} ${active && "bg-[#EEEEFC]"}`}>
      {children}
    </button>
  )
}


const TaggedInput = ({label, placeholder, tags, setTags}) => {
  const [value, setValue] = useState("")
  const handleKeyPress = (e) => {
    if (e.key === "Enter"){
      setTags([...tags, e.target.value])
      setValue("")
    }
  }
  return(
    <div className="my-6">
      <label className="text-sm text-slate-400">{label}</label>
      <input 
      type="text" 
      value={value}
      onChange={(e) => setValue(e.target.vaue)}
      onKeyDown={handleKeyPress}
      placeholder={placeholder} className="block w-full rounded-xl"
      />
      <div className="flex mt-4 flex-wrap items-center gap-2">
        {tags && tags.map(tag => 
          <p className="bg-[#EEEEFC] py-2 px-4 flex gap-2 items-center rounded-3xl">
            {tag} <MdClose onClick={() => {setTags(tags.filter(val => val !== tag))}} className="hover:cursor-pointer" color="red"/>
          </p>
        )}
      </div>
    </div>
  )
}

const TaggedSelect = ({label, listItems, placeholder, tags, setTags}) => {
  const handleSelect = (e) => {
    if (tags.includes(e.target.value)){
      return
    }
    setTags([...tags, e.target.value])
  }
  return(
    <div className="my-6">
      <label className="text-sm text-slate-400">{label}</label>
      <select 
      onChange={handleSelect}
      className="block w-full rounded-xl">
        <option value="">{placeholder}</option>
        {listItems && listItems.map(item =>
          <option value={item}>{item}</option>
        )}
      </select>
      <div className="flex mt-4 flex-wrap items-center gap-2">
        {tags && tags.map(tag => 
          <p className="bg-[#EEEEFC] py-2 px-4 flex gap-2 items-center rounded-3xl">
            {tag} <MdClose onClick={() => {setTags(tags.filter(val => val !== tag))}} className="hover:cursor-pointer" color="red"/>
          </p>
        )}
      </div>
    </div>
  )
}


export {Button, Toggle, TaggedInput, TaggedSelect}