import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import customPagination from '../../../components/CustomPagination/CustomPagination';
import { BackButton, SkeletonTable } from '../../../components';
import { useLocation } from 'react-router-dom';
import { useStateContext } from '../../../contexts/ContextProvider';
import { userRequest } from '../../../utils/requestMethods';
import * as Icons from "../../../data/teacher";

const customStyles = {
    headRow: {
        style: {
            backgroundColor: 'rgb(248, 250, 252)',
            fontWeight: "bold",
        },
    },
};

const ViewStudentAttendance = ({ history }) => {
    const location = useLocation();
    const studentId = location.pathname.split('/')[4];
    const student = location.state?.student;
    const classId = student?.class_info?._id;
    const { user, school } = useStateContext();
    const schoolTerm = school?.settings?.current_term;
    const schoolSession = school?.settings?.current_session;
    const [studentAttendance, setStudentAttendance] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchAttendance = async () => {
        setLoading(true);
        try {
            const { data } = await userRequest.get(`/attendance/student/${studentId}?class_id=${classId}&school_id=${school?._id}&term=${schoolTerm}&session=${schoolSession}&limit=50`);
            //console.log(data);
            setStudentAttendance(data?.data || []);
        } catch (error) {
            console.error("Error fetching attendance:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        let isMounted = true; // Prevent state update after unmount
        const fetchData = async () => {
            if (isMounted) {
                await fetchAttendance();
            }
        };
        fetchData();

        return () => {
            isMounted = false; // Cleanup function
        };
    }, [school?._id, user?._id]);

    // Calculate Days Present
    const daysPresent = studentAttendance.reduce((total, record) => {
        return total + record.days.filter(day => day.status === 1).length;
    }, 0);

    // Calculate Days Absent
    const daysAbsent = studentAttendance.reduce((total, record) => {
        return total + record.days.filter(day => day.status === 2).length;
    }, 0);

    // Calculate Total Days
    const totalDays = studentAttendance.reduce(
        (total, record) => total + record.days.length,
        0
    );

    // Calculate Attendance Rate
    const attendanceRate = totalDays > 0 ? Math.round((daysPresent / totalDays) * 100) : 0;

    const columns = [
        {
            name: "Week",
            selector: row => row.week,
        },
        {
            name: "Date Range",
            selector: row => {
                const days = row.days.map(day => new Date(day.date));
                const sortedDays = days.sort((a, b) => a - b); // Sort dates in ascending order
                const startDate = sortedDays[0];
                const endDate = sortedDays[sortedDays.length - 1];
                return `${startDate?.toLocaleDateString('en-NG')} - ${endDate?.toLocaleDateString('en-NG')}`;
            },
        },
        {
            name: "Monday",
            cell: row => {
                const monday = row.days.find(day => day.day_of_week === 1); // 1 for Monday
                if (!monday) {
                    return <img src={Icons.studentPending} alt="Pending Icon" />;
                }
                return monday.status === 1 ? (
                    <img src={Icons.studentPresent} alt="Present Icon" />
                ) : (
                    <img src={Icons.studentAbsent} alt="Absent Icon" />
                );
            },
        },
        {
            name: "Tuesday",
            cell: row => {
                const tuesday = row.days.find(day => day.day_of_week === 2); // 2 for Tuesday
                if (!tuesday) {
                    return <img src={Icons.studentPending} alt="Pending Icon" />;
                }
                return tuesday.status === 1 ? (
                    <img src={Icons.studentPresent} alt="Present Icon" />
                ) : (
                    <img src={Icons.studentAbsent} alt="Absent Icon" />
                );
            },
        },
        {
            name: "Wednesday",
            cell: row => {
                const wednesday = row.days.find(day => day.day_of_week === 3); // 3 for Wednesday
                if (!wednesday) {
                    return <img src={Icons.studentPending} alt="Pending Icon" />;
                }
                return wednesday.status === 1 ? (
                    <img src={Icons.studentPresent} alt="Present Icon" />
                ) : (
                    <img src={Icons.studentAbsent} alt="Absent Icon" />
                );
            },
        },
        {
            name: "Thursday",
            cell: row => {
                const thursday = row.days.find(day => day.day_of_week === 4); // 4 for Thursday
                if (!thursday) {
                    return <img src={Icons.studentPending} alt="Pending Icon" />;
                }
                return thursday.status === 1 ? (
                    <img src={Icons.studentPresent} alt="Present Icon" />
                ) : (
                    <img src={Icons.studentAbsent} alt="Absent Icon" />
                );
            },
        },
        {
            name: "Friday",
            cell: row => {
                const friday = row.days.find(day => day.day_of_week === 5); // 5 for Friday
                if (!friday) {
                    return <img src={Icons.studentPending} alt="Pending Icon" />;
                }
                return friday.status === 1 ? (
                    <img src={Icons.studentPresent} alt="Present Icon" />
                ) : (
                    <img src={Icons.studentAbsent} alt="Absent Icon" />
                );
            },
        },
        {
            name: "Attendance Rate",
            cell: row => {
                const totalDays = row.days.length;
                const daysPresent = row.days.filter(day => day.status === 1).length;
                const rate = Math.round((daysPresent / totalDays) * 100);
                return <p className="text-[#3DCE3D]">{rate}%</p>;
            },
        },
    ];
    
    return (
        <div className='w-full flex items-center justify-center py-14 md:py-8 relative'>
            <div className='bg-transparent w-full pb-16 px-3 md:px-9 py-8 sm:py-0'>
                <div className='bg-white rounded-2xl p-6'>
                    <BackButton history={history} />
                    <div className='w-14 h-14 rounded-full bg-blue-500 mb-4 mt-4 border-[3px] border-[#D8846E]'></div>

                    <div className='flex flex-wrap items-center justify-between'>
                        <div className="w-full sm:w-max mb-4 sm:mb-0">
                            <p className="text-2xl font-semibold mb-2">{student?.firstname} {student?.lastname}</p>
                            <p className="text-sm text-[#606060] font-medium">
                                {schoolTerm} Term - {schoolSession} Attendance
                            </p>
                        </div>
                        <button className='hover:bg-indigo-900 rounded-full text-sm text-white px-3 py-2 bg-[#3F3DA5]'>
                            Export Attendance
                        </button>
                    </div>

                    <div className='border-t border-t-[#E7E7E7] mt-6 mb-6'></div>

                    <div className='bg-[#EEEEFC] rounded-xl flex items-center justify-between'>
                        <div className='p-4'>
                            <p className='text-sm text-[#606060] mb-2'>Days Present</p>
                            <p className='text-3xl font-semibold'>{daysPresent}</p>
                        </div>
                        <div className='p-4'>
                            <p className='text-sm text-[#606060] mb-2'>Days Absent</p>
                            <p className='text-3xl font-semibold'>{daysAbsent}</p>
                        </div>
                        <div className='p-4'>
                            <p className='text-sm text-[#606060] mb-2'>Attendance Rate</p>
                            <p className='text-3xl font-semibold'>
                                {attendanceRate}%
                            </p>
                        </div>
                    </div>
                </div>

                <div className='bg-white rounded-2xl mt-6 px-6 py-7'>
                    <p className='text-2xl font-semibold mb-3'>Attendance</p>
                    <div className="border rounded-3xl">
                        <DataTable
                            columns={columns}
                            data={studentAttendance}
                            pagination
                            paginationComponent={customPagination}
                            paginationRowsPerPageOptions={[10, 20, 30, 50]}
                            customStyles={customStyles}
                            progressPending={loading}
                            progressComponent={<SkeletonTable rows={5} columns={6} />}
                            highlightOnHover
                            striped
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewStudentAttendance;
