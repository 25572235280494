import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { userRequest } from '../../utils/requestMethods';
import { FiMoreHorizontal } from 'react-icons/fi';
import { Header, CustomPagination, TableDropdown, SkeletonTable, StatusBadge, AddTeacherModal } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import useKYCStatusRedirect from '../../hooks/useKYCStatusRedirect';
import * as Icons from '../../data/teacher';

const customStyles = {
  headRow: {
    style: {
      backgroundColor: 'rgb(248, 250, 252)',
      fontWeight: "bold",
    },
  },
};

const TeacherManagement = ({ history }) => {
  //useKYCStatusRedirect("/management/teacher");
  const { school } = useStateContext();
  const [classes, setClasses] = useState();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const schoolId = school?._id;
  const schoolCode = school?.code;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState(null);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  //console.log(accessCode);
  //console.log(schoolCode);

  const fetchClasses = async () => {
    if (schoolCode) {
      try {
        setLoading(true);
        const { data } = await userRequest.get(`/teachers?school_id=${schoolId}&limit=100`);
        console.log(data.teachers);
        setClasses(data.teachers);
        setLoading(false);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchClasses();
  }, [schoolCode]);

  const deleteHandler = async (classId) => {
    //console.log(classId);
    try {
      await userRequest.delete(`classes/${schoolId}/${classId}`);
      //console.log(res.data);
      //setClasses(res.data.classes)
      fetchClasses();
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleDropdownClick = (rowIndex, event) => {
    const rect = event.target.getBoundingClientRect();
    setDropdownIndex(dropdownIndex === rowIndex ? null : rowIndex);
    setDropdownPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
  };

  const handleCloseDropdown = () => setDropdownIndex(null);

  const columns = [
    {
      name: "Name",
      selector: row => row.firstname +", "+ row.lastname
    },
    {
      name: "Gender",
      selector: row => row.gender
    },
    {
      name: "Email",
      selector: row => row.email
    },
    {
      name: "Phone Number",
      selector: row => row.mobile_number
    },
    {
      name: "Date of Employment",
      selector: row => (new Date(row.metadata.date_of_employment)).toLocaleDateString('en-NG')
    },
    {
      name: "Status",
      selector: row => (
        <div>
          <StatusBadge status="active" />
        </div>
      )
    },
    {
      cell: (row, rowIndex) => (
        <div className="relative">
          <button
            className="p-2 text-gray-600 hover:text-gray-900"
            onClick={(e) => handleDropdownClick(rowIndex, e)}
          >
            <FiMoreHorizontal className="text-2xl" />
          </button>
          <TableDropdown
            isVisible={dropdownIndex === rowIndex}
            position={dropdownPosition}
            options={[
              /*{
                label: "Resend Invite",
                icon: <img src={Icons.resend} alt="Payout Icon" />,
                //onClick: () => history.push(`/students/${row._id}`),
              },*/
              {
                label: "View Details",
                icon: <img src={Icons.teacherView} alt="Payout Icon" />,
                onClick: () => {
                  history.push(`/management/teacher/${row._id}`, { teacher: row });
                },
              },
              {
                label: "Delete",
                icon: <img src={Icons.delete} alt="Payout Icon" />,
                onClick: () => deleteHandler(row._id),
                danger: true,
              },
            ]}
            onClose={handleCloseDropdown}
          />
        </div>
      ),
      width: "120px",
    },
  ];

  return (
    <>
    <div className="px-10 pt-10">
      <button type="button" onClick={toggleModal} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-[#3F3DA5] hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right">
        Add New Teacher
      </button>
      <Header category="Teacher" title="Teacher Management" />
    </div>
    
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl dark:bg-secondary-dark-bg min-h-screen">
      <div className="border rounded-3xl">
        <DataTable
          data={classes}
          columns={columns}
          getRowId={(row) => row._id}
          pagination
          paginationComponentOptions={{
            rowsPerPageText: 'Rows per page:',
            rangeSeparatorText: 'of',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'All',
          }}
          paginationComponent={CustomPagination}
          paginationRowsPerPageOptions={[10, 20, 30, 50]}
          customStyles={customStyles}
          progressPending={loading}
          progressComponent={<SkeletonTable rows={5} columns={6} />}
        />
      </div>

      {isModalOpen && 
        <AddTeacherModal 
          onClose={() => setIsModalOpen(false)}
          onTeacherAdded={fetchClasses}
          schoolId={schoolId}
          schoolCode={schoolCode}
        />
      }
    </div>
    </>
  );
};
export default TeacherManagement;
