import React from 'react'
import { Button } from '../adminDashboard/ResultsComponents'
import empty from '../../data/icons/empty.svg'

const NoDataComponent = () => {
  return (
    <div className="my-20 mx-auto max-w-[400px] w-full aspect-square flex flex-col justify-center items-center text-gray-400 shadow-sm">
      <img alt="empty table" src={empty} />
      <p className="font-semibold text-xl my-4">No data to display yet.</p>
    </div>
  )
}

export default NoDataComponent