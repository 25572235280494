import React, { useState } from "react";
import { userRequest } from "../../../utils/requestMethods";

const AddCommentModal = ({
  closeModal,
  school,
  schoolId,
  onResultUploaded,
  childId,
  teacherId,
  classId,
  subject,
}) => {
  const [comment, setComment] = useState("");
  const [remark, setRemark] = useState("");
  const [skillsRatings, setSkillsRatings] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleRatingChange = (skill, rating) => {
    setSkillsRatings((prev) => ({ ...prev, [skill]: rating }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!comment || !remark) {
      setError("Please fill in both the comment and remark.");
      setTimeout(() => setError(""), 5000);
      return;
    }

    const selectedSkills = Object.entries(skillsRatings).map(([skill, rating]) => ({
      skill,
      rating,
    }));

    if (selectedSkills.length !== school?.settings?.remark?.skills?.length) {
      setError("Please provide ratings for all skills.");
      setTimeout(() => setError(""), 5000);
      return;
    }

    setLoading(true);

    try {
      // Prepare the payload
      const payload = {
        remark,
        comment,
        schoolId:school._id,
        session: school?.settings?.current_session,
        term: school?.settings?.current_term,
        skills: selectedSkills,
      };

      // API POST request
      await userRequest.post(`/result/remark/${childId}`, payload);

      setSuccess(true);
      setTimeout(() => setSuccess(false), 2000);
      //closeModal(); // Close modal on success
    } catch (error) {
      console.error(error);
      setError("An error occurred while saving the result.");
      setTimeout(() => setError(""), 5000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex items-center justify-center z-50">
      <div className="bg-white rounded-2xl w-full md:w-1/3 p-6 overflow-y-auto max-h-[90vh]">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold">{subject?.subject_name} Result</h2>
          <button
            type="button"
            onClick={closeModal}
            className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="authentication-modal"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <div className="mt-4">
          <p className="text-sm text-gray-500 mt-2">
            Enter student General observation and comment
          </p>
          <div className="mt-3">
            {error && (
              <div
                className="p-4 mb-4 text-sm text-white rounded-lg bg-red-500"
                role="alert"
              >
                <span className="font-medium">Error!</span> {error}
              </div>
            )}
            {success && (
              <div
                className="p-4 mb-4 text-sm text-white rounded-lg bg-green-500"
                role="alert"
              >
                <span className="font-medium">Success!</span> Comments saved
                successfully.
              </div>
            )}
            <form onSubmit={submitHandler} className="space-y-4">
              <div>
                <label
                  htmlFor="comment"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Enter Teacher Comment
                </label>
                <input
                  type="text"
                  id="comment"
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                  placeholder="E.g Excellent, Needs improvement etc."
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="remark"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Enter General Remark
                </label>
                <input
                  type="text"
                  id="remark"
                  onChange={(e) => setRemark(e.target.value)}
                  value={remark}
                  placeholder="E.g Excellent, Needs improvement etc."
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <div className="p-4 border rounded-3xl">
                {school?.settings?.remark?.skills?.map((skill, index) => (
                  <div
                    key={index}
                    className="flex flex-wrap gap-3 items-start justify-between mx-auto w-full my-4"
                  >
                    <div>
                      <p className="block text-base font-semibold">{skill}</p>
                    </div>
                    <div>
                      <select
                        onChange={(e) => handleRatingChange(skill, e.target.value)}
                        value={skillsRatings[skill] || ""}
                        className="block w-full md:w-100 max-w-full mt-1 rounded-md bg-gray-100 border-gray-300 focus:border-gray-500 focus:bg-white focus:ring-0"
                      >
                        <option value="">Select</option>
                        {school?.settings?.remark?.ratings?.map((rate, index) => (
                          <option key={index} value={rate}>
                            {rate}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex justify-between items-center">
                <button
                  type="button"
                  onClick={closeModal}
                  className="text-gray-600 bg-white border border-gray-300 font-semibold rounded-full text-sm px-5 py-2 mr-3"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center"
                >
                  {loading ? "Saving..." : "Save Comments"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCommentModal;
