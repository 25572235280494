import React, { useState } from 'react';
import { userRequest } from '../../../utils/requestMethods';

const AddExamModal = ({ closeModal, school, schoolId, teacherId, childId, subjectId, classId, goback, onStudentCreated, }) => {
  const [formData, setFormData] = useState({
    caScores: school?.settings?.assessment?.ca?.cas.map(() => 0), // Initialize scores for each CA
  });
  const [score, setScore] = useState("");
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const validateScores = () => {
    return school?.settings?.assessment?.ca?.cas.every(
      (ca, index) => formData.caScores[index] <= ca.max_score
    );
  };

  const submitHandler = async (e) => {
    e.preventDefault();
  
    if (!validateScores()) {
      setError('Provided scores exceed the maximum allowed score.');
      setTimeout(() => setError(''), 5000);
      return;
    }
  
    setLoading(true);
  
    try {
      const payload = {
        childId: childId,
        schoolId,
        session: school?.settings?.current_session,
        term: school?.settings?.current_term,
        classId,
        subjectId: subjectId,
        score,
      };
  
      // Send the payload in a single request
      await userRequest.post('/result/exam', payload);
      //onStudentCreated();
      //closeModal();
      setSuccess(true);
      setTimeout(() => setSuccess(false), 2000);
    } catch (error) {
      console.error(error);
      setError('An error occurred while adding the student result.');
      setTimeout(() => setError(''), 5000);
    }
  
    setLoading(false);
  };
  

  return (
    <div className="mt-4">
      <p className="text-sm text-gray-500 mt-2">Enter student Exam result</p>
      <div className="mt-3">
        {error && (
          <div className="p-4 mb-4 text-sm text-white rounded-lg bg-red-500" role="alert">
            <span className="font-medium">Error!</span> {error}
          </div>
        )}
        {success && (
          <div className="p-4 mb-4 text-sm text-white rounded-lg bg-green-500" role="alert">
            <span className="font-medium">Success!</span> Exam Scores Saved
          </div>
        )}
        <form className="space-y-4" onSubmit={submitHandler}>
          <div className="flex flex-wrap gap-3 items-start justify-between mx-auto w-full p-4 bg-[#C5C5E8] border rounded-3xl">
            <p className="text-base font-semibold">Total Weight</p>
            <div>
              <p className="text-sm">Total Exam Score</p>
              <div className="flex justify-between gap-2 items-center">
                <div className="rounded-sm overflow-hidden">
                  <input type="number" id="maxScore" value={school?.settings?.assessment?.exam?.max_score} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" disabled />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap gap-3 items-start justify-between mx-auto w-full p-4 border rounded-3xl">
            <div>
              <p className="text-base font-semibold">
                Student Exam Score
              </p>
            </div>
            <div>
              <p className="text-sm">Enter Score</p>
              <div className="flex justify-between gap-2 items-center">
                <input
                  type="number"
                  onChange={(e) => setScore(e.target.value)} value={score}
                  placeholder="Enter Score"
                  className="bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5"
                  max={school?.settings?.assessment?.exam?.max_score}
                  required
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <button type="button" onClick={goback} className="text-gray-600 bg-white border rounded-full text-sm px-5 py-2">
              &larr; Back
            </button>
            <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-full text-sm px-5 py-2">
              {loading ? 'Saving...' : 'Save Exam Scores'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddExamModal;
