import React, { useState } from 'react'
import ButtonLoading from '../ButtonLoading'
import { userRequest } from '../../utils/requestMethods';

const AddSubjectModal = ({ closeModal, schoolId, classId, goback, onSubjectCreated }) => {
  const [subjectName, setSubjectName] = useState("");
  const [subjectDescription, setSubjectDescription] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!subjectName || !subjectDescription) {
      setError("Please Fill all the Feilds");
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
      return;
    }
    try {
      await userRequest.post("/subjects",
        { 
          schoolId:schoolId,
          classId: classId, 
          subject_name:subjectName,
          subject_description: subjectDescription,
        });
      //console.log(data);
      onSubjectCreated();
      closeModal();
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setSubjectName("");
        setSubjectDescription("");
      }, 2000);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
    }
    setLoading(false);
  };

  return(
    <div className="mt-4">
      <p className="text-sm text-gray-500 mt-2">
        Enter subject details
      </p>
      <div className="mt-3">
        {error &&
        <div className="p-4 mb-4 text-sm text-white rounded-lg bg-red-500" role="alert">
            <span className="font-medium">Error!</span> {error}
        </div>}
        {success &&
        <div className="p-4 mb-4 text-sm text-white rounded-lg bg-green-500" role="alert">
          <span className="font-medium">Success!</span> New Subject Added
        </div>}
        <form onSubmit={submitHandler} class="space-y-4">
          <div>
            <label htmlFor="subjectName" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
            <input type="text" id="subjectName" onChange={(e) => setSubjectName(e.target.value)} value={subjectName} placeholder="First Name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
          </div>
          <div>
            <label htmlFor="lastName" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
            <textarea type="text" id="subject_description" onChange={(e) => setSubjectDescription(e.target.value)} value={subjectDescription} placeholder="Last Name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required></textarea>
          </div>
          <div className="flex justify-between items-center">
            <button type="button" onClick={goback} className="text-gray-600 bg-white border border-gray-300 font-semibold rounded-full text-sm px-5 py-2 mr-3">&larr; Back</button>
            <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center">
                {loading ? 'Adding...' : 'Add Subject'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default AddSubjectModal